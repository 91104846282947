import { createSelector } from "@ngxs/store"
import { PARTS_STATE_TOKEN, PartsStateModel } from "./state"

export class PartsSelectors {
  static getByPartNumber(_partNumber: string) {
    return createSelector([PARTS_STATE_TOKEN], (state: PartsStateModel) => {
      return state.items.filter(({ partNumber }) => partNumber === _partNumber)
    })
  }

  /** Assembly specific methods */
  static getAssembliesByType(type: string) {
    return createSelector([PARTS_STATE_TOKEN], (state: PartsStateModel) => {
      return state.items.filter(({ toolType }) => toolType === type)
    })
  }
}
