export interface TpiCoordinator {
    name: string;
    role: string;
    emails: string[];
    phoneNumbers: PhoneNumber[];
    customer: string;
    toolInspectionTypes: string[];
    backUpBoolean: boolean;
}

export class TpiCoordinator {
    constructor(data: Partial<TpiCoordinator>) {
        this.name = data.name || null;
        this.role = data.role || null;
        this.emails = data.emails || null;
        this.phoneNumbers = data.phoneNumbers || null;
        this.customer = data.customer || null;
        this.toolInspectionTypes = data.toolInspectionTypes|| null;
        this.backUpBoolean = data.backUpBoolean|| null;
    }
}

export interface PhoneNumber {
    label: string;
    number: string;
}