import { Injectable } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { Functions, HttpsCallable, httpsCallable, httpsCallableFromURL } from '@angular/fire/functions';
import { skipWhile, Subscription, tap, timer } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from '../state/auth.state';
import { AuthActions } from '../state/auth.actions';
import { Auth, authState } from '@angular/fire/auth';


@Injectable({
  providedIn: 'root'
})
export class RefreshGapiTokenService {

  private refreshgapitoken: HttpsCallable<unknown, unknown> | (() => Promise<any>);
  private refreshTimerSubscription: Subscription;

  constructor(
    private auth: Auth,
    private functions: Functions,
    private store: Store,
  ) {
    this.refreshgapitoken = httpsCallable(this.functions, 'refreshgapitokenv18');
  }

  public get expiresAt() {
    const token = this.store.selectSnapshot(AuthState.gapiTokens);
    return token && token.expiresAt
      ? new Date(new Date().setTime((token?.expiresAt?._seconds - 600) * 1000))
      : 0
  }

  public get isExpired() {
    const now = Timestamp.now().toDate();
    const expiresAt = this.expiresAt;
    return now >= expiresAt;
  }

  public async refreshToken() {
    // Make sure user is available before attempting to refresh the token.

    // this.store.select(AuthState.claims)
    authState(this.auth)
      .pipe(
        tap(console.log),
        // tap(claims => console.log('refreshToken.started claims', { claims })),
        skipWhile(user => user === null),
        // tap(claims => console.log('refreshToken.past skipWhile.claims', { claims })),
        tap(async () => {
          await this.refreshgapitoken()
            .then(result => {
              console.log('gapiRefreshTokenCallableFunction.result', { result });
              gapi.client.setToken({ access_token: result.data.access_token });
              this.store.dispatch(new AuthActions.Update({ gapi: result.data }));
              this.setRefreshTimer();
            })
            .catch(console.error)
        })
      ).subscribe();
  }

  public setRefreshTimer() {
    if (this.refreshTimerSubscription) {
      this.refreshTimerSubscription.unsubscribe();
    }
    this.refreshTimerSubscription = this.refreshTimer(this.expiresAt);
  }

  private refreshTimer(expiresAt: Date | number) {
    console.log('RefreshGapiTokenService.refreshTimer().started', `expiresAt: ${expiresAt}`)
    return timer(expiresAt)
      .pipe(
        tap(async () => await this.refreshToken()),
      ).subscribe()
  }
}
