import { Component, computed, EventEmitter, input, Input, OnChanges, OnDestroy, OnInit, Output, Signal, SimpleChanges } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, skipWhile, takeUntil, tap } from 'rxjs/operators';

import { createSelectMap, select, Store } from '@ngxs/store';

import { TableStateActions } from '../state/table.actions';
import { TableState } from '../state/table.state';

import { BarcodeScannerComponent } from '../../barcode-scanner/barcode-scanner.component';
import { MatInputModule } from '@angular/material/input';


@Component({
  selector: 'lib-table-filter-qr',
  templateUrl: './table-filter-qr.component.html',
  styleUrls: [
    './table-filter-qr.component.css',
  ],
  standalone: true,
  imports: [
    BarcodeScannerComponent,
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
  ]
})
export class TableFilterQrComponent implements OnChanges, OnDestroy {
  ngDestroy$ = new Subject();

  @Input({ required: true }) tableName!: string;
  sortActive = input<string>();

  @Output() filterChange? = new EventEmitter<string>();
  @Output() enterKeyup? = new EventEmitter<string>();

  filterString: FormControl = new FormControl(null);

  placeHolderText: Signal<string>;


  constructor(
    private dialog: MatDialog,
    private store: Store,
  ) {

    this.placeHolderText = computed(() => this.sortActive() ? `Filter by ${this.sortActive()}` : 'Select a column to filter');

    this.filterString.valueChanges
      .pipe(
        takeUntil(this.ngDestroy$),
        distinctUntilChanged(),
        debounceTime(500),
        skipWhile(value => value == null)
      ).subscribe(value => {
        this.store.dispatch(new TableStateActions.UpdateState(
          this.tableName,
          { filterText: value?.toUpperCase() }
        ))
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterString.setValue(
      this.store.selectSnapshot(TableState.tableQuery(this.tableName))?.filterText
    );
  }

  openScannerDialog(): void {
    this.dialog.open(BarcodeScannerComponent)
      .afterClosed()
      .pipe(
        takeUntil(this.ngDestroy$),
        tap((result: string) => {
          this.filterString.setValue(result);
        })
      ).subscribe();
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

}
