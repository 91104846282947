import { QueryConstraint } from "@angular/fire/firestore";
import { Container as Model } from "@dis/models";
const title = 'CONTAINERS';
export { StateActions as ContainersStateActions }

namespace StateActions {
  export class Delete {
    public static readonly type = `[${title}] Delete`;
    constructor(public payload: Model) { }
  }
  export class GetAll {
    public static readonly type = `[${title}] Get all`;
  }

  export class GetQuery {
    public static readonly type = `[${title}] Get query`;
  }

  export class FilterList {
    public static readonly type = `[${title}] Filter list`;
    constructor(public payload: string) { }
  }

  export class StartFirestoreCollectionWatcher {
    public static readonly type = `[${title}] Start Firestore Collection Watcher`;
  }

  export class UpdateFromFirestoreWatcher {
    public static readonly type = `[${title}] Update from Firestore Watcher`;
    constructor(public payload: Model[]) { }
  }

  export class Update {
    public static readonly type = `[${title}] Update`;
    constructor(public payload: Model) { }
  }

  export namespace Firestore {
    export class Get {
      public static readonly type = `[${title}] Firestore Get`;
      constructor(public payload: string) { }
    }
    export class GetAll {
      public static readonly type = `[${title}] Firestore Get All`;
    }
    export class GetPages {
      public static readonly type = `[${title}] Firestore Get Pages`;
      constructor(public payload: {
        queryConstraints: QueryConstraint[],
        pageSize: number,
        active: string,
        direction: 'asc' | 'desc'
      }) { }
    }
    export class GetQuery {
      public static readonly type = `[${title}] Firestore Get Query`;
      constructor(public payload: QueryConstraint[]) { }
    }
  }
}
