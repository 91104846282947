
export class Dimensionals {
    od: string;
    id: string;
    weight: string;
    casingWeight: string;
    grade: string;
    material: string;
    lengthClosed: string;
    lengthOpen: string;
    workingLength: string;
    overallLength: string;
    drift: string;
}

export class Connections {
    topConnection: string;
    bottomConnection: string;
}