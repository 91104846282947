import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";

import { DeleteButtonComponent } from "./delete-button/delete-button.component";
import { FileListComponent } from "./file-list";
import { RawDataExpansionComponent } from "./raw-data-expansion/raw-data-expansion.component";
import { MatDialogModule } from "@angular/material/dialog";
import { HasRoleDirective } from "./directives";
import { NdeDatesComponent } from './nde/nde-dates/nde-dtates.component';


const COMPONENTS = [
  DeleteButtonComponent,
  FileListComponent,
  HasRoleDirective,
  RawDataExpansionComponent,
  NdeDatesComponent,
]

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
  ],
  exports: [
    ...COMPONENTS,
  ]
})
export class SharedComponentsModule { }
