<div class="container">

  <button mat-icon-button color="warn" [matTooltip]="canDelete ? 'Confirm' : 'Delete file?'" (click)="canDelete ? deleteItem() : prepareForDelete()">
    <mat-icon>delete</mat-icon>
  </button>

  @if (canDelete) {
    <button mat-icon-button matTooltip="Cancel" (click)="cancel()">
      <mat-icon>cancel</mat-icon>
    </button>
  }

</div>
