import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { take } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private authService: AuthService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): Promise<boolean> | boolean {
    const url = `/${route.path}`;
    const result = this.checkLogin(url);
    return result;
  }

  checkLogin(url: string): Promise<boolean> | boolean {
    return this.authService.isLoggedIn
    .pipe(
      take(1)
    ).toPromise();
  }
}
