import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, ReplaySubject, tap } from 'rxjs';
import { Store } from '@ngxs/store';

import { Auth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut, User } from '@angular/fire/auth';

import { AuthActions } from '../state/auth.actions';
import { AppUser } from '../models';

import { SCOPES } from '@dis/gapi';

import * as Sentry from '@sentry/angular';


interface Claims {
  access_token?: any;
  admin?: boolean;
  roles?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _isLoggedIn$ = new ReplaySubject<boolean>(1);
  private _user$ = new ReplaySubject<User>(1);
  private _claims$ = new ReplaySubject<any>(1);
  private _claims: Claims;

  private provider = new GoogleAuthProvider();
  public user: User;


  constructor(
    private auth: Auth,
    private router: Router,
    private store: Store,
  ) {

    onAuthStateChanged(this.auth, async (_user) => {
      // console.log('onAuthStateChanged.ran', { _user });
      if (_user) {
        this._isLoggedIn$.next(Boolean(_user));
        this._user$.next(_user);
        this._claims$.next(Boolean(_user) ? this.getUserClaims(_user) : null);

        Sentry.setUser({
          id: _user.uid,
          email: _user.email,
          username: _user.displayName
        });

      } else {
        this._isLoggedIn$.next(false);
        this._user$.next(null);
        this._claims$.next(null);
      }
    })

  }

  get isLoggedIn(): Observable<boolean> {
    return this._isLoggedIn$.asObservable();
  }

  get user$(): Observable<User> {
    return this._user$.asObservable();
  }

  get claims$(): Observable<Claims> {
    return this._claims$.asObservable();
  }

  get claims(): Claims {
    return this._claims;
  }

  private async getUserClaims(user: User) {
    if (user) {
      this.store.dispatch(new AuthActions.SetUser(new AppUser(user, 'drillingllc.com')));
      const token = await user.getIdTokenResult(true);
      const claims = token.claims;
      this._claims = claims as Claims;
      this.store.dispatch(new AuthActions.Claims(claims));
      return claims;
    } else {
      return user;
    }
  }

  async signIn() {
    console.log('authService.signIn().ran')
    this.provider.setCustomParameters({
      access_type: 'offline',
      prompt: 'consent',
    })
    SCOPES.forEach(scope => this.provider.addScope(scope));
    await signInWithPopup(this.auth, this.provider)
      .then((result) => {
        // this.googleUser = result;
      })
      .catch((error) => {
        console.error(error)
      })
  }

  // TODO: this should not be called by the service.
  // The service should be called by the state actions watching the dispatch.
  signOut() {
    this.store.dispatch(new AuthActions.Signout(signOut(this.auth)));
  }


  ///// Role-based Authorization //////
  canRead(): boolean {
    const allowed = ['admin', 'editor', 'viewer'];
    return this.checkAuthorization(allowed);
  }

  canEdit(): boolean {
    const allowed = ['admin', 'editor'];
    return this.checkAuthorization(allowed);
  }

  canDelete(): boolean {
    const allowed = ['admin'];
    return this.checkAuthorization(allowed);
  }

  isEngineering(): boolean {
    const allowed = ['engineering'];
    return this.checkAuthorization(allowed);
  }

  // determines if user has matching role
  private checkAuthorization(allowedRoles: string[]): boolean {
    return this.checkClaimsForAllowedRoles(this._claims, allowedRoles);
  }

  private checkClaimsForAllowedRoles(claims: Claims, allowedRoles: string[]): boolean {
    const roles = claims.roles;
    return roles.some(role => allowedRoles.includes(role));
  }

}
