<div class="container">


    @if(showConnections()) {
    <small>{{part?.topConnection}}</small>
    }


  <img [src]="imageUrl"
    onerror="javascript:this.src='assets/icons/icon-64x64.png'">


    @if(showConnections()) {
    <small>{{part?.bottomConnection}}</small>
    }


</div>
