
import { Timestamp } from '@angular/fire/firestore';
import { convertToFirestoreTimestamp } from './_global';

export class ComponentRecord {
  name: string;
  recordId: string;
  date: Timestamp;
  fileId: string;
  fileUrl: string;

  static fromFile(file): ComponentRecord {
    const document = new ComponentRecord();
    document.name = file.Name || null;
    document.recordId = file.properties?.description || null;
    document.date = convertToFirestoreTimestamp(file.properties?.documentDate) || null;
    document.fileId = file.Id || null;
    document.fileUrl = file.Url || null;
    return document;
  }
}
