import { Injectable } from '@angular/core';
import { SheetsStoreService } from '../../../services';
import { COLLECTIONS, FOLDERID, Part } from '@dis/models'

@Injectable({
  providedIn: 'root'
})
export class PartsSheetStore extends SheetsStoreService<Part> {

  protected collectionName = COLLECTIONS.PARTS;
  protected override collectionRows = '1:1500';

  async getPartThumbnailLink(partNumber: string) {
    const files = await this.driveApi.getFiles(FOLDERID.PARTIMAGES);
    const file = files.filter(file => file.Name === `${partNumber}.png`)
    return file[0]?.ThumbnailLink || null;
  }
}
