import { AfterContentInit, Component, ContentChild, ContentChildren, input, Input, QueryList, TrackByFunction, untracked, ViewChild } from '@angular/core';
import { MatHeaderRowDef, MatRowDef, MatColumnDef, MatNoDataRow, MatTable, MatTableModule, MatFooterRowDef } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';

import { TableDataSource } from '../tables.datasource';
import { TableFilterQrComponent } from '../table-filter-qr/table-filter-qr.component';
import { FirestoreTableDataSource } from '../firestore-tables.datasource';
import { TableStateActions } from '../state/table.actions';
import { createDispatchMap } from '@ngxs/store';
import { Sort } from '@angular/material/sort';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'lib-collections-wrapper-table',
  templateUrl: './collections-wrapper-table.component.html',
  styleUrls: [
    './collections-wrapper-table.component.css',
  ],
  standalone: true,
  imports: [
    MatPaginatorModule,
    MatTableModule,
    TableFilterQrComponent,
  ]
})
export class CollectionsWrapperTable<T> implements AfterContentInit {
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;
  @ContentChildren(MatFooterRowDef) footerRowDefs: QueryList<MatFooterRowDef>;
  @ContentChildren(MatHeaderRowDef) headerRowDefs: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs: QueryList<MatRowDef<T>>;
  @ContentChild(MatNoDataRow) noDataRow: MatNoDataRow;

  // @ViewChild('sort', { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<T>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  columns = input.required<string[]>();

  @Input({ required: true }) dataSource: FirestoreTableDataSource<T> | TableDataSource<T>;

  tableName = input.required<string>();

  @Input({ required: true }) trackByFn: TrackByFunction<T>;

  page = input<PageEvent>();
  queryCount = input<number>(0);
  sort = input<Sort>();

  ngAfterContentInit() {
    this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
    this.footerRowDefs.forEach(footerRowDef => this.table.addFooterRowDef(footerRowDef));
    this.headerRowDefs.forEach(headerRowDef => this.table.addHeaderRowDef(headerRowDef));
    this.rowDefs.forEach(rowDef => this.table.addRowDef(rowDef));
    this.table.setNoDataRow(this.noDataRow);
  }

  actions = createDispatchMap({
    updateTablePage: TableStateActions.UpdatePage,
  })

  handlePage(event: PageEvent) {
    this.actions.updateTablePage(this.tableName(), event);
  }
}
