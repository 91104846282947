<mat-nav-list>
  @for (document of documents; track document.Id; let i = $index) {
    <mat-list-item>
      <!-- <mat-icon matListItemIcon>{{document.IsFolder ? 'folder' : 'note'}}</mat-icon> -->
      <a matListItemTitle class="span" [href]="document.Url" target="_blank">{{document.Name}}</a>
      <!-- <div class="span" matListItemTitle>{{document.Name}}</div> -->
      <!-- <div matListItemLine>{{document.ModifiedTime | date}}</div> -->
      <!-- TODO @acadianaapps will reimplment this with media query to hide smaller devices.
      Currently don't need its to easy to delete files from drive -->
      <!-- <delete-button (delete)="handleDocumentDelete(document.Id, i)" matListItemMeta></delete-button> -->
    </mat-list-item>
  }
</mat-nav-list>
