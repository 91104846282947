export const MIME_TYPE_FOLDER = 'application/vnd.google-apps.folder';
export const MIME_TYPE_SHORTCUT = 'application/vnd.google-apps.shortcut';
export const DRIVE_FOLDER_URL = 'https://drive.google.com/drive/folders/';

export interface FileProperties {
  documentType?: string;
  purchaseOrder?: string;
  documentDate?: Date;
  description?: string;
  jobNumber?: string;
  buildNumber?: string;
  serialNumbers?: string;
}

export class FileInfo {

  AppProperties: Object;
  Blob: File;
  Id: string;
  MimeType: string;
  ModifiedTime: Date;
  Name: string;
  Progress: number;
  Size: string;
  ThumbnailLink: string;
  Url: string;
  Version: string;
  DownloadLink: string;
  ExportLinks: string[];
  Properties: FileProperties;
  Parents: string;
  createdTime: Date;


  static fromGoogleFile(file): FileInfo {
    const fileInfo = new FileInfo();
    fileInfo.Id = file.id;
    fileInfo.MimeType = file.mimeType;
    fileInfo.ModifiedTime = new Date(file.modifiedTime);
    fileInfo.Name = file.name;
    fileInfo.Size = file.size;
    fileInfo.ThumbnailLink = file.thumbnailLink;
    fileInfo.Url = file.webViewLink;
    fileInfo.Version = file.version;
    fileInfo.DownloadLink = file.webContentLink;
    fileInfo.ExportLinks = file.exportLinks;
    fileInfo.AppProperties = file.appProperties || {};
    fileInfo.Properties = file.properties || {};
    fileInfo.Parents = file.parents;
    fileInfo.createdTime = new Date(file.createdTime);
    return fileInfo;
  }


  public get Icon(): string {
    if (this.IsFolder) {
      return 'folder';
    } else {
      return 'file';
    }
  }

  public get IsFolder(): boolean {
    return this.MimeType === MIME_TYPE_FOLDER;
  }

  public get ModifiedTimeText(): string {
    return `${this.ModifiedTime.getDate()}.${(this.ModifiedTime.getMonth() + 1)}.${this.ModifiedTime.getFullYear()}`;
  }
}
