import { Injectable } from "@angular/core";
import { FirestoreV9Service } from "../../../services";
import { addDoc, collection } from "@angular/fire/firestore";
import { ShopEquipment } from "@dis/models";

@Injectable({
    providedIn: 'root'
})
export class ShopEquipmentFirestore extends FirestoreV9Service<ShopEquipment> {
    protected override basePath = "shop-equipment";
    override baseConverter = {
        fromFirestore: (
            snapshot: any,
            options: any,
        ) => {
            const data = snapshot.data(options)!;
            return new ShopEquipment(data);
        },
        toFirestore: (values: ShopEquipment) => Object.assign({}, values),
    }


    async createEquipment(value: ShopEquipment) {
        const collRef = collection(this.firestore, this.basePath);
        const docRef = await addDoc(collRef, value);
        value.uid = docRef.id;
        this.upsert(value.uid, value);
    }

    async updateEquipment(value: ShopEquipment) {
        this.upsert(value.uid, value);
    }
}