import { Container } from "@dis/models";
import { FirestoreV9Service } from "../../../services/firestore-v9.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ContainersFirestore extends FirestoreV9Service<Container> {

  protected override basePath: string = 'containers';

  protected override baseConverter = {
    fromFirestore: (
      snapshot: any,
      options: any,
    ) => {
      const data = new Container(snapshot.data(options));
      return data;
    },
    toFirestore: (values: Container) => values
  }

}
