import { Subject } from 'rxjs';
const hookSubject = Symbol('ngx-observable-lifecycle-hooks');
const hooksPatched = Symbol('ngx-observable-lifecycle-hooks-decorator');
function getSubjectForHook(componentInstance, hook) {
  if (!componentInstance[hookSubject]) {
    componentInstance[hookSubject] = {};
  }
  if (!componentInstance[hookSubject][hook]) {
    componentInstance[hookSubject][hook] = new Subject();
  }
  const proto = componentInstance.constructor.prototype;
  if (!proto[hooksPatched]) {
    proto[hooksPatched] = {};
  }
  if (!proto[hooksPatched][hook]) {
    const originalHook = proto[hook];
    proto[hook] = function (...args) {
      originalHook?.call(this, ...args);
      if (hook === 'ngOnChanges') {
        this[hookSubject]?.[hook]?.next(args[0]);
      } else {
        this[hookSubject]?.[hook]?.next();
      }
    };
    const originalOnDestroy = proto.ngOnDestroy;
    proto.ngOnDestroy = function () {
      originalOnDestroy?.call(this);
      this[hookSubject]?.[hook]?.complete();
      delete this[hookSubject]?.[hook];
    };
    proto[hooksPatched][hook] = true;
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return componentInstance[hookSubject][hook];
}
/**
 * Library authors should use this to create their own lifecycle-aware functionality
 */
function getObservableLifecycle(classInstance) {
  return new Proxy({}, {
    get(target, p) {
      return getSubjectForHook(classInstance, p).asObservable();
    }
  });
}

/*
 * Public API Surface of ngx-observable-lifecycle
 */

/**
 * Generated bundle index. Do not edit.
 */

export { getObservableLifecycle, hookSubject, hooksPatched };
