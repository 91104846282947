import { User } from "@angular/fire/auth";
export interface AppUser {
  uid: string;
  displayName: string;
  email: string | null;
  domain: string;
  claims?: {};
  roles?: string[];
}

export class AppUser {
  constructor(auc: User, hd: string) {
    this.uid = auc.uid;
    this.displayName = auc.displayName;
    this.email = auc.email;
    this.domain = hd;
  }
}

export type AclType = 'canView' | 'canEdit' | 'canDelete' | 'isEngineering';
