import { Sort } from "@angular/material/sort";
import { TableQuery } from "./table.state";
import { PageEvent } from "@angular/material/paginator";

const TITLE = 'Table';

export {
  Actions as TableStateActions
}

namespace Actions {
  export class UpdateFilter {
    public static readonly type = `[${TITLE}] UpdateFilter`;
    constructor(
      public tableName: string,
      public payload: string
      ) { }
  }
  export class UpdateState {
    public static readonly type = `[${TITLE}] UpdateState`;
    constructor(
      public tableName: string,
      public payload: Partial<TableQuery>
      ) { }
  }

  export class UpdateSort {
    public static readonly type = `[${TITLE}] UpdateSort`;
    constructor(
      public tableName: string,
      public payload: Sort,
      ) { }
  }

  export class UpdatePage {
    public static readonly type = `[${TITLE}] UpdatePage`;
    constructor(
      public tableName: string,
      public payload: PageEvent,
      ) { }
  }
}
