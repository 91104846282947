import { createSelector } from "@ngxs/store";
import { PROJECTS_STATE_TOKEN, ProjectsStateModel } from "./state";

export class ProjectsSelectors {
  static getByProjectNumber(projectNumber: string) {
    return createSelector([PROJECTS_STATE_TOKEN], (state: ProjectsStateModel) => {
      return state.items.filter(item => item.projectNumber === projectNumber);
    })
  }

  //   getDistinctProjectsByJobStatus$(status: string) {
  //     return this.jobs$.pipe(
  //       mergeAll(),
  //       filter(job => job.status === status),
  //       pluck('projectNumber'),
  //       distinct(),
  //       toArray(),
  //     );
  //   }
}
