import { ShopEquipment as Model } from "@dis/models";
import { QueryConstraint } from "@angular/fire/firestore";
const title = 'EQUIPMENT';
export { StateActions as EquipmentStateActions }

namespace StateActions {
  export class Delete {
    public static readonly type = `[${title}] Delete`;
    constructor(public payload: Model) { }
  }
  export class GetAll {
    public static readonly type = `[${title}] Get all`;
  }

  export class GetQuery {
    public static readonly type = `[${title}] Get query`;
    constructor(public payload: QueryConstraint[]) { }
  }

  export class FilterList {
    public static readonly type = `[${title}] Filter list`;
    constructor(public payload: string) { }
  }

  export class StartFirestoreCollectionWatcher {
    public static readonly type = `[${title}] Start Firestore Collection Watcher`;
  }

  export class UpdateFromFirestoreWatcher {
    public static readonly type = `[${title}] Update from Firestore Watcher`;
    constructor(public payload: Model[]) { }
  }

  export class Update {
    public static readonly type = `[${title}] Update`;
    constructor(public payload: Model) { }
  }

  export class Create {
    public static readonly type = `[${title}] Create`;
    constructor(public payload: Model) { }
  }
}
