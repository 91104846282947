import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Component as JtmsComponent } from '@dis/models';

@Component({
  selector: 'nde-dates',
  templateUrl: './nde-dates.component.html',
  styleUrls: ['./nde-dates.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NdeDatesComponent {
  @Input({ required: true }) item: JtmsComponent;
}
