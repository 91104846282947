import { Injectable } from '@angular/core';
import { FileInfo, FileProperties, MIME_TYPE_FOLDER, MIME_TYPE_SHORTCUT } from './models/fileInfo';

const FIELDS = [
  'appProperties',
  'capabilities',
  'createdTime',
  'description',
  'driveId',
  'explicitlyTrashed',
  'exportLinks',
  'fileExtension',
  'fullFileExtension',
  'hasThumbnail',
  'headRevisionId',
  'iconLink',
  'id',
  'imageMediaMetadata',
  'kind',
  'lastModifyingUser',
  'mimeType',
  'modifiedByMe',
  'modifiedByMeTime',
  'modifiedTime',
  'name',
  'originalFilename',
  'ownedByMe',
  'owners',
  'parents',
  'properties',
  'shared',
  'sharedWithMeTime',
  'sharingUser',
  'size',
  'spaces',
  'starred',
  'teamDriveId',
  'thumbnailLink',
  'thumbnailVersion',
  'trashed',
  'trashedTime',
  'trashingUser',
  'version',
  'viewedByMe',
  'viewedByMeTime',
  'webContentLink',
  'webViewLink',
  'writersCanShare',
];

@Injectable({
  providedIn: 'root'
})
export class GoogleDriveApiService {

  requestFields = {
    list: `nextPageToken, files(${FIELDS.join()})`,
    allFields: FIELDS.join(),
    fileInfo: 'id,description,webViewLink,name,mimeType,modifiedTime,size,properties',
    shortcutInfo: 'id,name,mimeType,shortcutDetails,properties',
    imgInfo: 'id,mimeType,thumbnailLink',
  };

  supportsSharedDrives = {
    supportsAllDrives: true,
    includeItemsFromAllDrives: true,
  };

  params = {
    ...this.supportsSharedDrives,
    corpora: 'drive',
    driveId: '',
    pageSize: 50,
    fields: this.requestFields.list,
    q: ''
  };

  async getThumbnailLink(fileId: string): Promise<string> {
    const response = await gapi.client.drive.files.get({
      ...this.supportsSharedDrives,
      fields: this.requestFields.imgInfo,
      fileId,
    });
    const result = response.result;
    console.log('google-drive-api.getThumbnailLink.result', result);
    return result.thumbnailLink;
  }

  /**
   * Get files from a shared drive
   * @param  {string} folderId
   */
  async getFiles(folderId: string): Promise<FileInfo[]> {
    const response = await gapi.client.drive.files.list({
      ...this.supportsSharedDrives,
      pageSize: 1000,
      fields: this.requestFields.list,
      q: `'${folderId}' in parents and trashed = false`
    });
    return response.result.files
      .map((file) => FileInfo.fromGoogleFile(file));
  }

  async getFolders(folderId: string): Promise<FileInfo[]> {
    const response = await gapi.client.drive.files.list({
      ...this.supportsSharedDrives,
      pageSize: 1000,
      fields: this.requestFields.list,
      q: `'${folderId}' in parents and mimeType = 'application/vnd.google-apps.folder' and trashed = false`
    });
    return response.result.files
      .map((file) => FileInfo.fromGoogleFile(file));
  }


  async exportFileAsPdfBlob(fileId: string) {
    const response = await gapi.client.drive.files.export({
      fileId,
      mimeType: "application/pdf",
    })
    const { body } = response;
    // @ts-ignore
    return Buffer.from(body).toString('base64url');
  }

  async getFileBlob(fileId: string) {
    const response = await gapi.client.drive.files.get({
      ...this.supportsSharedDrives,
      alt: 'media',
      fileId,
    })
    const { result, body } = response;
    // Convert binary data to blob.
    const data = body;
    const len = data.length;
    const ar = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      ar[i] = data.charCodeAt(i);
    }
    const blob = new Blob([ar], { type: 'application/pdf' });
    return ar;
  }

  async getFilesByName(fileName: string, parent?: string): Promise<FileInfo[]> {
    const response = await gapi.client.drive.files.list({
      ...this.supportsSharedDrives,
      pageSize: 10,
      fields: this.requestFields.list,
      q: `'${parent}' in parents and name = '${fileName}' and mimeType = 'application/pdf' and trashed = false`,
    });
    return response.result.files
      .map((file) => FileInfo.fromGoogleFile(file));
  }

  async getFolderByName(folderName: string, parent?: string): Promise<FileInfo[]> {
    const response = await gapi.client.drive.files.list({
      ...this.supportsSharedDrives,
      pageSize: 10,
      fields: this.requestFields.list,
      q: `'${parent}' in parents and name = '${folderName}' and mimeType = 'application/vnd.google-apps.folder' and trashed = false`,
    });
    return response.result.files
      .map((file) => FileInfo.fromGoogleFile(file));
  }

  async getFileById(fileId: string): Promise<FileInfo> {
    const response = await gapi.client.drive.files.get({
      ...this.supportsSharedDrives,
      fields: this.requestFields.list,
      fileId: fileId,
    });
    return FileInfo.fromGoogleFile(response.result);
  }

  async getFolderById(folderId: string): Promise<FileInfo[]> {
    const response = await gapi.client.drive.files.list({
      ...this.supportsSharedDrives,
      pageSize: 1000,
      fields: this.requestFields.list,
      q: `'${folderId}' in parents and mimeType = 'application/vnd.google-apps.folder' and trashed = false`
    });
    return response.result.files
      .map((file) => FileInfo.fromGoogleFile(file));
  }

  async renameFile(fileId: string, newName: string) {
    await gapi.client.drive.files.update({
      ...this.supportsSharedDrives,
      fields: this.requestFields.list,
      fileId: fileId,
      resource: {
        name: newName,
      }
    });
  }

  // WIP - only returns the 59 most recent modified files; using masterAppScript getInvoiceFileId() function to do this task
  async getCustomerInvoiceFiles(invoiceNumber: number): Promise<FileInfo[]> {

    const invoiceNumberString = `${invoiceNumber}`;
    const customerInvoiceFolders = await this.getFolders('1lOlk66JGRub10b-7ATVTjBPukmRkWbcA');
    console.log(customerInvoiceFolders);

    let query = '';
    customerInvoiceFolders.map((subFolder, index) => {

      query += `'${subFolder.Id}' in parents`;
      if (index + 1 !== customerInvoiceFolders.length) {
        query += ' or '
      }
    });
    query += ` and trashed = false`;
    console.log(query);

    let nextPageToken = null;
    let filteredList: FileInfo[] = [];
   
    do {
      const response = await gapi.client.drive.files.list({
        ...this.supportsSharedDrives,
        pageSize: 500,
        fields: this.requestFields.list,
        q: query,
        pageToken: nextPageToken,
      });

      nextPageToken = response.result.nextPageToken;
      const files = response.result.files
        .map((file) => FileInfo.fromGoogleFile(file)).filter(file => file.Name.includes(invoiceNumberString));
      if (files.length > 0) {
        filteredList.push(...files);
      }
      console.log(response.result.files);
      console.log('ran', response.result.nextPageToken, response.result.files.length);
    } while (nextPageToken);
    return filteredList;
  }



  async watchFolderChanges(folderId: string) {
    const response = await gapi.client.drive.files.watch({
      ...this.supportsSharedDrives,
      fileId: folderId,
      resource: {
        id: this.uuid(),
        type: 'web_hook',
        address: 'https://jtms.drillingllc.com/watchdrivehook',
      }
    })
    console.log('watchFolderChanges', response.body);
  }

  /**
   * Currently this createFile is specific to uploading csv data from pressure graph charts.
   * @param  {} data
   * @param  {string} fileName?
   * @param  {string} parentFolder?
   */
  async uploadCsvData(data, fileName?: string, parentFolder?: string) {
    console.log('drive-api-service.createFile.data', { data, fileName, parentFolder });
    const mimeType = 'text/csv';
    const file = data;
    const metadata = {
      name: `${fileName}.csv`, // Filename at Google Drive
      mimeType, // mimeType at Google Drive
      parents: parentFolder ? [parentFolder] : null, // Folder ID at Google Drive
    };

    const accessToken = gapi.auth.getToken().access_token; // Here gapi is used for retrieving the access token.
    const form = new FormData();
    form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
    form.append('file', file);

    const res = await fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&supportsAllDrives=true&convert=true', {
      method: 'POST',
      headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
      body: form,
    });
    const val = FileInfo.fromGoogleFile(res.json());
    console.log('drive-api-service.uploadCsvData.response', val);
    // res.ok ? alert('Upload successful') : alert('Upload failed!');
    return val;
  }

  /**
   * @param  {string} fileId
   * @param  {} properties
   * @param  {boolean} trashed?
   */
  async updateFileMetaData(fileId: string, properties: FileProperties, trashed?: boolean): Promise<FileInfo> {

    properties = this.checkProperties(properties);
    const newFileName = `${this.convertDate(properties.documentDate)}_${properties.documentType}_(${properties.description})`;

    const body = {
      appProperties: { ...properties },
      properties: { ...properties },
      name: newFileName,
      trashed: trashed || null
    };

    const response = await gapi.client.drive.files.update({
      supportsAllDrives: true,
      fileId: fileId,
      fields: this.requestFields.fileInfo,
      //@ts-ignore
      resource: body
    });

    return FileInfo.fromGoogleFile(response.result);
  }

  // Currently this createFile is specific to uploading PNG images from pressure graph.
  async uploadPngImage(data, fileName?: string, parentFolder?: string) {
    console.log('drive-api-service.createFile.data', { data, fileName, parentFolder });
    const mimeType = 'image/png';
    const file = this.dataURItoBlob(data);
    const metadata = {
      name: `${fileName}.png`, // Filename at Google Drive
      mimeType, // mimeType at Google Drive
      parents: parentFolder ? [parentFolder] : null, // Folder ID at Google Drive
    };

    const accessToken = gapi.auth.getToken().access_token; // Here gapi is used for retrieving the access token.
    const form = new FormData();
    form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
    form.append('file', file);

    const res = await fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&supportsAllDrives=true', {
      method: 'POST',
      headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
      body: form,
    });
    const val = FileInfo.fromGoogleFile(res.json());
    console.log('drive-api-service.uploadPngImage.response', val);
    // res.ok ? alert('Upload successful') : alert('Upload failed!');
    return val;
  }

  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/png' });
  }

  /**
   * Copy file
   * @param  {FileInfo} file
   * @param  {string} parent?
   * @param  {} properties?
   */
  async copyFile(file: FileInfo, parent?: string | null, properties?: any): Promise<FileInfo> {
    console.log('google-drive-api.service.copyFile() running');

    let newFileName = file.Name;

    if (properties) {
      properties = this.checkProperties(properties);
      newFileName = this.renameDoc(properties);
    }

    parent = parent || null;

    const response = await gapi.client.drive.files.copy({
      supportsAllDrives: true,
      fileId: file.Id,
      fields: this.requestFields.fileInfo,

      resource: {
        parents: [parent],
        properties,
        name: newFileName
      }
    });
    console.log('copyFile.response.result', response.result);
    return FileInfo.fromGoogleFile(response.result);
  }

  /**
   * Create folder
   * @param  {string} parentId
   * @param  {string} folderName
   */
  async createFolder(parentId: string, folderName: string): Promise<FileInfo> {
    const folder = {
      name: folderName,
      mimeType: MIME_TYPE_FOLDER,
      parents: [parentId]
    };
    const res = await gapi.client.drive.files.create({
      supportsAllDrives: true,
      resource: folder,
      fields: 'id, name, mimeType, modifiedTime, size'
    });
    return FileInfo.fromGoogleFile(res.result);
  }

  /**
   * Create file shortcut and add to new location
   * @param  {FileInfo} file
   * @param  {string} parentId
   * @param  {FileProperties} properties
   */
  async createFileShortcut(file: FileInfo, parentId: string | null, properties: FileProperties): Promise<FileInfo> {
    console.log('google-drive-api.service.createFileShortcut() running', { file, parentId, properties });

    let newFileName = file.Name;

    if (properties) {
      properties = this.checkProperties(properties);
      newFileName = this.renameDoc(properties);
    }

    const res = await gapi.client.drive.files.create({
      supportsAllDrives: true,
      fields: this.requestFields.shortcutInfo,
      resource: {
        parents: [parentId],
        name: newFileName,
        mimeType: MIME_TYPE_SHORTCUT,
        shortcutDetails: {
          targetId: file.Id,
          targetMimeType: file.MimeType,
        }
      }
    });
    console.log('createFileShortcut.response.result', res.result);
    return FileInfo.fromGoogleFile(res.result);
  }

  /**
   * @param  {FileInfo} file
   * @param  {string} parent?
   * @param  {} properties?
   */
  async moveFile(file: FileInfo, parent?: string | null, properties?: FileProperties | { [x: string]: string; } | undefined): Promise<FileInfo> {
    console.log('google-drive-api.service.moveFile() running...');
    let newFileName = file.Name;

    if (properties) {
      properties = this.checkProperties(properties);
      newFileName = this.renameDoc(properties);
    }

    parent = parent || null;

    const response = await gapi.client.drive.files.update({
      supportsAllDrives: true,
      fileId: file.Id,
      removeParents: file.Parents,
      addParents: parent,
      fields: this.requestFields.fileInfo,

      resource: {
        //@ts-ignore
        properties,
        name: newFileName
      }
    });
    console.log('moveFile.response.result', response.result);
    return FileInfo.fromGoogleFile(response.result);
  }


  async delete(fileId: string) {
    console.log('google-drive-api.service.delete() running...', fileId);
    const responce = gapi.client.drive.files.delete({
      supportsAllDrives: true,
      fields: this.requestFields.fileInfo,
      fileId: fileId
    });
    return responce;
  }

  private checkProperties(properties: FileProperties) {
    if (properties && properties.serialNumbers && properties.serialNumbers.length > 100) {
      properties.serialNumbers = null;
      return properties;
    } else {
      return properties;
    }
  }

  private renameDoc(properties: FileProperties) {
    const { documentDate, documentType, description } = properties;
    return `${this.convertDate(documentDate)}_${documentType}_(${description})`;
  }

  convertDate(date: Date) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  uuid() {
    //@ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g,
      (c: number) => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
}
