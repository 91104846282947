import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'can-deactivate-dialog',
  templateUrl: './can-deactivate-dialog.component.html',
  styleUrls: ['./can-deactivate-dialog.component.css']
})
export class CanDeactivateDialogComponent {

  constructor(private dialogRef: MatDialogRef<CanDeactivateDialogComponent>  ) { }

  Ok(){
    this.dialogRef.close(true);
  }

  No(){
    this.dialogRef.close(false);
  }

}
