import { Quote } from "@dis/models";
export { StateActions as QuotesStateActions }

namespace StateActions {
  export class Delete {
    public static readonly type = "[QUOTES] Delete";
    constructor(public payload: Quote) { }
  }
  export class GetAll {
    public static readonly type = "[QUOTES] Get all";
  }

  export class GetQuery {
    public static readonly type = "[QUOTES] Get query";
  }

  export class FilterList {
    public static readonly type = "[QUOTES] Filter list";
    constructor(public payload: string) { }
  }

  export class StartCollectionSheetWatcher {
    public static readonly type = "[QUOTES] Start Collection Sheet Watcher";
  }

  export class UpdateFromSheetWatcher {
    public static readonly type = "[QUOTES] Update state from Sheet Watcher";
    constructor(public payload: Quote[]) { }
  }

  export class Update {
    public static readonly type = "[QUOTES] Update";
    constructor(public payload: Quote) { }
  }
}
