import { createPickSelector, createPropertySelectors, createSelector } from "@ngxs/store";
import { AuthState, AuthStateModel } from "./auth.state";

export class AuthStateSelectors {
  static slices  = createPropertySelectors<AuthStateModel>(AuthState);

  static state = createSelector([AuthState], (state: AuthStateModel) => state);

  static gapi = createPickSelector(this.state, ['gapi']);
}
