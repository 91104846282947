import { Injectable, inject, isDevMode } from "@angular/core";
import { Router } from "@angular/router";
import { Firestore, FirestoreDataConverter, Query, QueryConstraint, collection, collectionData, doc, docData, query, setDoc, updateDoc } from "@angular/fire/firestore";

import { Observable, tap } from "rxjs";

import { SnackbarService } from "./snackbar.service";

import { GoogleDriveApiService } from "@dis/gapi";
import { Build, FOLDERID, Job, Project } from "@dis/models";

@Injectable()
export abstract class FirestoreV9Service<T> {
  protected abstract basePath: string;
  protected abstract baseConverter: FirestoreDataConverter<T>;

  private serviceName = isDevMode() ? 'Emulator' : 'Firestore';

  protected firestore: Firestore = inject(Firestore);
  protected driveApi: GoogleDriveApiService = inject(GoogleDriveApiService);
  protected snackbar: SnackbarService = inject(SnackbarService);
  protected router: Router = inject(Router);


  doc$<D = T>(uid: string): Observable<D> {
    const docRef = doc(this.firestore, this.basePath, uid);
    // @ts-ignore
    return docData(docRef.withConverter(this.baseConverter))
      .pipe(
        tap(r => {
          if (isDevMode()) {
            console.groupCollapsed(`${this.serviceName} Streaming [${this.basePath}] [doc$] ${uid}`);
            console.log(r);
            console.groupEnd();
          }
        })
      ) as Observable<D>
  }

  private get collection() {
    return collection(this.firestore, this.basePath).withConverter(this.baseConverter);
  }

  /**
   * @param queryConstraints {QueryConstraint[]=[]} queryConstraints
   */
  collection$(queryConstraints: QueryConstraint[] = []): Observable<T[]> {
    return collectionData(
      query(this.collection, ...queryConstraints),
    )
  }

  list$() {
    const colRef = collection(this.firestore, this.basePath);
    console.log(colRef.id);

  }

  public upsert(id: string, value) {
    return id
      ? this.update(id, value)
      : this.create(value);
  }

  private async create(value) {
    const docRef = doc(this.collection).withConverter(this.baseConverter);
    const id = docRef.id;
    value.id = id;
    await setDoc(docRef, value);
  }

  private async update(id: string, value) {
    const docRef = doc(this.collection, id).withConverter(this.baseConverter);
    await updateDoc(docRef, value);
    // return of(id);
  }

  /** Helpers */


  private showStatus(message: string) {
    this.snackbar.openSnackBar(`${this.basePath.toUpperCase()} ${message}`, null);
  }

  public async checkFolder(resource: Project | Job | Build, parent?: string): Promise<string> {
    if (resource.folderId) {
      return resource.folderId;
    } else {
      const parentId = FOLDERID[this.basePath] ? FOLDERID[this.basePath.toUpperCase()] : parent;
      const folder = await this.driveApi.createFolder(parentId, resource.title);
      return folder.Id;
    }
  }

  public async deleteFile(id: string) {
    await this.driveApi.delete(id);
  }
}
