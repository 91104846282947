import { Injectable } from "@angular/core";

import { tap } from "rxjs";

import { NgxsAfterBootstrap, Action, Selector, State, StateContext, StateToken, createSelector, NgxsOnInit } from "@ngxs/store";
import { iif, insertItem, patch, updateItem } from "@ngxs/store/operators";

import { BaseCollectionsState } from "../../../states/base.state";

import { TableQuery, TableState, TableStateActions } from "../../../components/tables";


/**
 *  ~~~~ ONLY EDIT BETWEEN THESE COMMENTS ~~~~
 */

import { COLLECTIONS, GeoJson, Container as Model } from "@dis/models";
import { ContainersStateActions as StateActions } from "./actions";
import { ContainersFirestore } from "../service/firestore";
import { Disconnected, Emitted, NgxsFirestoreConnect, StreamDisconnected, StreamEmitted } from "@ngxs-labs/firestore-plugin";
import { query } from "@angular/fire/firestore";
import { upsertItem } from "../../operators/ngxs-upsertItem";

const COLLECTION = COLLECTIONS.CONTAINERS;

export {
  StateModel as ContainersStateModel,
  STATE_TOKEN as CONTAINERS_STATE_TOKEN,
  StateStore as ContainersState
};

/**
 * ^^^^ ONLY EDIT BETWEEN THESE COMMENTS ^^^^
 */

const STATE_TOKEN = new StateToken<StateModel>(`${COLLECTION.toLowerCase()}State`);

interface StateModel {
  loading: boolean;
  items: Model[];
}

@State({
  name: STATE_TOKEN,
  defaults: {
    loading: false,
    items: [],
  }
})
@Injectable()
class StateStore extends BaseCollectionsState implements NgxsOnInit, NgxsAfterBootstrap {
  /**
   *  DO NOT ADD SELCTORS HERE USE THE "stateName.selectors.ts file"
   */

  @Selector([STATE_TOKEN])
  static items(state: StateModel) {
    return state.items;
  }

  @Selector([StateStore.items])
  static count(items: Model[]) {
    return items.length;
  }

  @Selector([StateStore.items])
  static mapGeoJson(items: Model[]) {
    return items.filter(items => items.trackable)
      .map(item => {
        const { lon, lat } = item;
        return new GeoJson([lon, lat], item);
      });
  }

  @Selector([StateStore.items, TableState.tableQuery(COLLECTION)])
  static tableItems(items: Model[], { sort }: TableQuery) {
    const { active, direction } = sort;
    //@ts-ignore
    return this.sortByActive(items, active, direction);
  }

  @Selector([StateStore.tableItems, TableState.tableQuery(COLLECTION)])
  static filtered(items: Model[], { filterText }: TableQuery) {
    return filterText
      ? [...items.filter(item => this.filterPredicate(item, filterText))]
      : [...items]
  }

  static getById(uid: string) {
    return createSelector([StateStore.items], (items: Model[]) => {
      return items.filter(item => item.uid === uid)[0];
    })
  }

  /**
   * @deprecated moving to getByStatuses so can provide more than one status
   * @param  {string} status
   */
  static getByStatus(status: string) {
    return createSelector([StateStore.items], (items: Model[]) => {
      return items.filter(item => item.status === status);
    })
  }

  static getByStatuses(statuses: string[]) {
    return createSelector([StateStore.items], (items: Model[]) => {
      return items.filter(item => statuses.includes(item.status));
    })
  }

  // Firestore temporary specific selectors
  @Selector([StateStore.items])
  static firestoreItems(items: Model[]) {
    return items;
  }

  /**
   * @deprecated using this in place of firestore count server will migrate
   * @param items
   * @returns
   */
  @Selector([StateStore.firestoreItems])
  static queryCount(items: Model[]) {
    return items.length;
  }

  constructor(
    private db: ContainersFirestore,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
  ) {
    super();
  }

  ngxsOnInit(): void {
    //query collection
    this.ngxsFirestoreConnect.connect(StateActions.Firestore.GetQuery, {
      to: ({ payload }) => this.db.collection$((ref) => query(ref, ...payload)),
    })

    // query doc
    this.ngxsFirestoreConnect.connect(StateActions.Firestore.Get, {
      to: (action) => this.db.doc$(action.payload),
      trackBy: (action) => action.payload,
    })
  }


  // Firestore Get Query
  @Action(StreamEmitted(StateActions.Firestore.GetQuery))
  getQuery({ dispatch, getState, patchState, setState }: StateContext<StateModel>, { action, payload }: Emitted<StateActions.Firestore.GetQuery, Model[]>) {
    patchState({ loading: true });
    setState(
      patch({
        items: payload
      })
    );
    patchState({ loading: false });
  }

  // Firestore Get
  @Action(StreamEmitted(StateActions.Firestore.Get))
  get(ctx: StateContext<StateModel>, { action, payload }: Emitted<StateActions.Firestore.Get, Model>) {
    ctx.setState(
      patch({
        items: iif(
          (items) => !!items.find((item) => item.uid === payload.uid),
          // @ts-ignore  not sure why this is not working
          updateItem((item: Model) => item.uid === payload.uid, patch(payload)),
          insertItem(payload)
        )
      })
    );
  }

  // Firestore Disconnects
  @Action(StreamDisconnected(StateActions.Firestore.GetQuery))
  getQueryDisconnected(ctx: StateContext<StateModel>, { action }: Disconnected<StateActions.Firestore.GetQuery>) {
    console.log('getQueryDisconnected', { action });
  }

  ngxsAfterBootstrap({ dispatch, patchState }: StateContext<any>): void {
    // patchState({ loading: true });
    // dispatch(new StateActions.GetAll);
  }

  @Action(StateActions.GetAll, { cancelUncompleted: true })
  getAll({ patchState, dispatch }: StateContext<StateModel>) {
    patchState({ loading: true });
    return this.db.collection$()
      .pipe(
        tap(items => patchState({
          loading: false,
          items
        })),
      );
  }

  // @Action(StateActions.StartFirestoreCollectionWatcher)
  // async watchCollection({ dispatch, patchState }: StateContext<StateModel>) {
  //   patchState({ loading: true });
  //   return this.db.collection$().pipe(
  //     mergeMap(items => dispatch(new StateActions.UpdateFromFirestoreWatcher(items)))
  //   )
  // }

  @Action(StateActions.UpdateFromFirestoreWatcher)
  updateState({ patchState, setState }: StateContext<StateModel>, { payload: items }: StateActions.UpdateFromFirestoreWatcher) {
    items.map(_item => {
      setState(
        patch<StateModel>({
          items: upsertItem<Model>(item => item.uid === _item.uid, _item),
        })
      )
    }
    )
    patchState({ loading: false })
  }

  /**
   *
   *
   * Below are actions that dispatch CRUD operations to the db handling the collection
   *
   */

  @Action(StateActions.Update)
  update({ patchState }: StateContext<StateModel>, { payload }: StateActions.Update) {
    patchState({ loading: true });

    const item = payload;
    console.log({ item })
    // item.folderId = await this.db.checkFolder(item);
    this.db.upsert$(item);

    patchState({ loading: false });
  }

  @Action(StateActions.Delete)
  async delete({ patchState }: StateContext<StateModel>, { payload }: StateActions.Delete) {
    patchState({ loading: true });

    const item = payload;
    // await this.db.deleteFile(item.folderId);
    // await this.db.delete(item.uid);

    patchState({ loading: false })
  }

}

