import { createSelector } from "@ngxs/store"
import { COMPONENTS_STATE_TOKEN, ComponentsStateModel } from "./state"
import { Component } from "@dis/models"

export class ComponentsSelectors {
  static getByPartNumber(_partNumber: string) {
    return createSelector([COMPONENTS_STATE_TOKEN], (state: ComponentsStateModel) => {
      return state.items.filter(({ partNumber }) => partNumber === _partNumber)
    })
  }

  /** Assembly specific methods */
  static getAssembliesByInventoryRack(_rack: string) {
    return createSelector([COMPONENTS_STATE_TOKEN], (state: ComponentsStateModel) => {
      return state.items.filter(({rack}) => rack === _rack);
    })
  }

  static getAssembliesByType(type: string) {
    return createSelector([COMPONENTS_STATE_TOKEN], (state: ComponentsStateModel) => {
      return state.items.filter(({ assemblyNumber, toolType }) => toolType === type && assemblyNumber)
    })
  }

  static getAssembliesByTypeAndInventoryStatus(type: string) {
    return createSelector([this.getAssembliesByType(type)], (components: Component[]) => {
      return components.sort((a, b) => a.inventoryStatus < b.inventoryStatus ? -1 : 1)
    })
  }

  static getByPartNumberAndStatus(id: string, equalsOperatorBoolean: boolean, status: string) {
    return createSelector([this.getByPartNumber(id)], (components: Component[]) => {
      return components.filter(component => equalsOperatorBoolean
        ? component.inventoryStatus === status
        : component.inventoryStatus !== status
      )
    })
  }
}
