import { createPropertySelectors, createSelector } from "@ngxs/store";
import { JOBS_STATE_TOKEN, JobsState, JobsStateModel } from "./state";
import { Job } from "@dis/models";

export class JobsSelectors {
  static slices = createPropertySelectors<JobsStateModel>(JobsState);

  static getByBillingStatus(billingStatus: string) {
    return createSelector([JobsSelectors.slices.items], (items: Job[]) => {
      return items.filter(job => job.billing.status === billingStatus);
    })
  }

  static getByProjectNumber(projectNumber: string) {
    return createSelector([JobsSelectors.slices.items], (items: Job[]) => {
      return items.filter(job => job.projectNumber === projectNumber);
    })
  }

  static getByQuoteNumber(quoteNumber: string) {
    return createSelector([JOBS_STATE_TOKEN], (state: JobsStateModel) => {
      return state.items.filter(job => job.jobNumber.includes(quoteNumber));
    })
  }

  static getRecentByDate(status: string, amount: number) {
    const getDateTime = (inputDate) => { return inputDate != null ? new Date(inputDate).getTime() : 0; }
    return createSelector([JobsState.getByStatus(status)], (jobs: Job[]) => {
      return status === 'SHIPPED'
        ? jobs.sort((a, b) => getDateTime(b.dateOutArray[0]) - getDateTime(a.dateOutArray[0])).slice(0, amount)
        : jobs.sort((a, b) => getDateTime(b.dateInArray[b.dateInArray.length - 1]) - getDateTime(a.dateInArray[b.dateInArray.length - 1])).slice(0, amount)

    })
  }

  //   getDistinctProjectsByJobStatus$(status: string) {
  //     return this.jobs$.pipe(
  //       mergeAll(),
  //       filter(job => job.status === status),
  //       pluck('projectNumber'),
  //       distinct(),
  //       toArray(),
  //     );
  //   }
}
