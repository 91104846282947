import { Invoice } from "./invoice";

export interface JobBilling {
    customer: string;
    status: string;
    invoices: number[];
    billingCode: BillingCode;
    otherBillingReferences: BillingCode[];
}

export interface BillingCode {
    name: string;
    number: string;
    quoteReference: string | number;
}

export enum BillingCodeNames {
    LOUPO = 'LOU Purchase Order',
    PO = 'Purchase Order',
    NETWORK = 'Network Number',
    AFE = 'AFE Number',
    MATERIAL = 'Material Number',
    WBS = 'WBS Number',
}

export const billingCodeOptions = () => {
    let billingCodeOptions: string[] = [];

    for (const code in BillingCodeNames) {
        billingCodeOptions.push(BillingCodeNames[code]);
    }
    return billingCodeOptions;
}



