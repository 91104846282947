import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  customClaims,
  hasCustomClaim
} from '@angular/fire/auth-guard';


export const adminOnly = () => hasCustomClaim('admin');

export const allowModule = (next) => pipe(
  customClaims,
  map(claims => claims[`${next.params.module}-role`] === true )
);

export const allowModuleEdit = (next) => pipe(
  customClaims,
  map(claims => claims[`${next.params.module}`] === true )
);
