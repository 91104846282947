import { createPropertySelectors, createSelector } from "@ngxs/store";
import { QUOTES_STATE_TOKEN, QuotesStateModel } from "./state";

export class QuotesSelectors {
  static slices = createPropertySelectors<QuotesStateModel>(QUOTES_STATE_TOKEN);

  static getByProjectNumber(projectNumber: string) {
    return createSelector([QUOTES_STATE_TOKEN], (state: QuotesStateModel) => {
      return state.items.filter(item => item.projectNumber === projectNumber);
    })
  }

  //   getDistinctProjectsByJobStatus$(status: string) {
  //     return this.jobs$.pipe(
  //       mergeAll(),
  //       filter(job => job.status === status),
  //       pluck('projectNumber'),
  //       distinct(),
  //       toArray(),
  //     );
  //   }
}
