import { pipe, skipWhile, take, tap } from "rxjs"

export const appAfterBootstrap = () => {
  return pipe(
    // tap((value) => console.log('ngxsAfterBootstrap.ran.start', { value })),
    skipWhile(value => !value),
    // tap((value) => console.log('ngxsAfterBootstrap.ran.afterSkipWhile()', { value })),
    take(1),
  )
}
