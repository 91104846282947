import { Injectable } from '@angular/core';
import { SheetsStoreService } from '../../../services';
import { Build, COLLECTIONS } from '@dis/models'


@Injectable({
  providedIn: 'root'
})
export class BuildsSheetStore extends SheetsStoreService<Build> {

  protected collectionName = COLLECTIONS.BUILDS;
  protected override collectionRows = '1:3000';

}
