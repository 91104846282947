@if ((auth.user$ | async); as user) {
  @if (user) {
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar class="header-image"></div>
        <mat-card-title>
          {{ user.displayName }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>Claims</p>
        <pre>{{ auth.claims$ | async | json }}</pre>
        <p>User</p>
        <pre>{{ user | json }}</pre>
      </mat-card-content>
      <mat-card-actions>
        <button mat-stroked-button color="warn" (click)="auth.signOut()">Logout</button>
      </mat-card-actions>
    </mat-card>
  }
} @else {
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Click below to login.
      </mat-card-title>
    </mat-card-header>
    <mat-card-actions>
      <button mat-stroked-button color="primary" (click)="auth.signIn()">Login</button>
    </mat-card-actions>
  </mat-card>
}

<ng-template #login>
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Click below to login.
      </mat-card-title>
    </mat-card-header>
    <mat-card-actions>
      <button mat-stroked-button color="primary" (click)="auth.signIn()">Login</button>
    </mat-card-actions>
  </mat-card>
</ng-template>
