import { Injectable } from '@angular/core';
import { SheetsStoreService } from '../../../services';
import { Assembly, COLLECTIONS } from '@dis/models'


@Injectable({
  providedIn: 'root'
})
export class AssembliesSheetStore extends SheetsStoreService<Assembly> {

  protected collectionName = COLLECTIONS.ASSEMBLYS;
  protected override collectionSheet? = 'TEST_DATA';;

}
