import { Component, Input } from '@angular/core';

@Component({
  selector: 'raw-data-expansion',
  templateUrl: './raw-data-expansion.component.html',
  styleUrls: ['./raw-data-expansion.component.css']
})
export class RawDataExpansionComponent {
  @Input() data: any;
}
