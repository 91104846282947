export interface JobReference {
  jobNumber: string;
  projectNumber: string;
}
export class Invoice {
  uid: number;
  invoiceNumber: number;
  dateOfInvoice: Date;
  collectionDate: Date;
  jobReference: string;
  projectReference: string;
  jobs: JobReference[];
  status: string;
  poReference: string;
  finalInvoiceBoolean: boolean;
  paidBoolean: boolean;
  invoiceFileId: string;

  constructor(data: Partial<Invoice>) {
    this.uid = data.uid;
    this.invoiceNumber = data.invoiceNumber;

    // Dates come from firestore as a timestamp, model switches properties to JS Date
    //@ts-expect-error
    this.dateOfInvoice = data.dateOfInvoice.toDate() || null;
    //@ts-expect-error
    this.collectionDate = data?.collectionDate?.toDate() || null;

    this.jobReference = data.jobReference || null;
    this.projectReference = data.projectReference || null;
    this.jobs = data.jobs || null;
    this.status = data.status || null;
    this.poReference = data.poReference || null;
    this.finalInvoiceBoolean = data.finalInvoiceBoolean || false;
    this.paidBoolean = data.paidBoolean || false;
    this.invoiceFileId = data.invoiceFileId || null;
  }

  get fileUrl() {
    return `https://drive.google.com/file/d/${this.invoiceFileId}/view`;
  }
}
