import { Injectable } from "@angular/core";

import { tap } from "rxjs";

import { NgxsAfterBootstrap, Action, Selector, State, StateContext, StateToken, createSelector } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { upsertItem } from "../../../jtms/operators/ngxs-upsertItem";

import { BaseCollectionsState } from "../../../states/base.state";

import { TableQuery, TableState } from "../../../components/tables";


/**
 *  ~~~~ ONLY EDIT BETWEEN THESE COMMENTS ~~~~
 */

import { COLLECTIONS, ShopEquipment as Model } from "@dis/models";
import { EquipmentStateActions as StateActions } from "./actions";
import { ShopEquipmentFirestore } from "../service/shop-equipment.firestore";
import { orderBy } from "@angular/fire/firestore";
import { where } from "firebase/firestore";

const COLLECTION = COLLECTIONS.EQUIPMENT;

export {
  StateModel as EquipmentStateModel,
  STATE_TOKEN as EQUIPMENT_STATE_TOKEN,
  StateStore as EquipmentState
};

/**
 * ^^^^ ONLY EDIT BETWEEN THESE COMMENTS ^^^^
 */

const STATE_TOKEN = new StateToken<StateModel>(`${COLLECTION.toLowerCase()}State`);

interface StateModel {
  loading: boolean;
  items: Model[];
  filterText: string;
}

@State({
  name: STATE_TOKEN,
  defaults: {
    loading: false,
    items: [],
    filterText: null,
  }
})
@Injectable()
class StateStore extends BaseCollectionsState implements NgxsAfterBootstrap {
  /**
   *  DO NOT ADD SELCTORS HERE USE THE "stateName.selectors.ts file"
   */

  @Selector([STATE_TOKEN])
  static items(state: StateModel) {
    return state.items;
  }

  @Selector([StateStore.items])
  static count(items: Model[]) {
    return items.length;
  }

  @Selector([StateStore.items, TableState.tableQuery(COLLECTION)])
  static tableItems(items: Model[], { active, direction }: TableQuery) {
    return this.sortByActive(items, active, direction);
  }

  @Selector([StateStore.tableItems, TableState.tableQuery(COLLECTION)])
  static filtered(items: Model[], { filterText }: TableQuery) {
    return filterText
      ? [...items.filter(item => this.filterPredicate(item, filterText))]
      : [...items]
  }

  static getByUid(uid: string) {
    return createSelector([StateStore.items], (items: Model[]) => {
      return items.filter(item => item.uid === uid);
    })
  }

  constructor(
    private db: ShopEquipmentFirestore,
  ) {
    super();
  }

  ngxsAfterBootstrap({ dispatch, patchState }: StateContext<any>): void {
    patchState({ loading: true });
    dispatch(new StateActions.GetQuery([
      orderBy('name', 'asc'),
    ]));
  }

  @Action(StateActions.FilterList, { cancelUncompleted: true })
  filterList({ patchState }: StateContext<StateModel>, { payload: filterText }: StateActions.FilterList) {
    patchState({ filterText })
  }

  @Action(StateActions.GetAll, { cancelUncompleted: true })
  getAll({ patchState, dispatch }: StateContext<StateModel>) {
    patchState({ loading: true });
    return this.db.collection$()
      .pipe(
        tap(items => patchState({
          loading: false,
          items
        })),
      )
  }

  @Action(StateActions.GetQuery, { cancelUncompleted: true })
  getQuery({ patchState, dispatch }: StateContext<StateModel>, { payload: query }: StateActions.GetQuery) {
    patchState({ loading: true });
    return this.db.collection$(query)
      .pipe(
        tap(items => patchState({
          loading: false,
          items
        })),
      )
  }

  @Action(StateActions.UpdateFromFirestoreWatcher)
  updateState({ patchState, setState }: StateContext<StateModel>, { payload: items }: StateActions.UpdateFromFirestoreWatcher) {
    items.map(_item => {
      setState(
        patch<StateModel>({
          items: upsertItem<Model>(item => item.uid === _item.uid, _item),
        })
      )
    }
    )
    patchState({ loading: false })
  }

  /**
   *
   *
   * Below are actions that dispatch CRUD operations to the db handling the collection
   *
   */
  @Action(StateActions.Create)
  async create({ patchState }: StateContext<StateModel>, { payload }: StateActions.Create) {
    patchState({ loading: true });
    const item = payload;
    await this.db.createEquipment(item);

    patchState({ loading: false });
  }


  @Action(StateActions.Update)
  async update({ patchState }: StateContext<StateModel>, { payload }: StateActions.Update) {
    patchState({ loading: true });

    const item = payload;
    await this.db.upsert(item.uid, item);

    patchState({ loading: false });
  }

  @Action(StateActions.Delete)
  async delete({ patchState }: StateContext<StateModel>, { payload }: StateActions.Delete) {
    patchState({ loading: true });

    const item = payload;
    // await this.db.deleteFile(item.folderId);
    // await this.db.delete(item.uid);

    patchState({ loading: false })
  }

}

