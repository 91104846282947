import { Inject, Injectable, InjectionToken, ModuleWithProviders, NgModule } from "@angular/core";
import { NGXS_PLUGINS, NgxsNextPluginFn, NgxsPlugin } from "@ngxs/store";
import { tap } from "rxjs";

export const NGXS_SHEETSAPI_PLUGIN_OPTIONS = new InjectionToken('NGXS_SHEETSAPI_PLUGIN_OPTIONS');

@Injectable()
export class SheetsApiPlugin implements NgxsPlugin {
  constructor(
    @Inject(NGXS_SHEETSAPI_PLUGIN_OPTIONS) private options: any,
  ) { }

  handle(state: any, action: any, next: NgxsNextPluginFn) {
    console.log('Action started!', state);
    return next(state, action).pipe(
      tap(result => {
        console.log('Action happened!', result);
      })
    );
  }
}

@NgModule()
export class NgxsSheetsApiModule {
  static forRoot(config?: any): ModuleWithProviders<NgxsSheetsApiModule> {
    return {
      ngModule: NgxsSheetsApiModule,
      providers: [
        {
          provide: NGXS_PLUGINS,
          useClass: SheetsApiPlugin,
          multi: true,
        },
        {
          provide: NGXS_SHEETSAPI_PLUGIN_OPTIONS,
          useValue: config,
        }
      ]
    }
  }
}
