import { BaseData } from './_global';
import { Dimensionals } from './dimensionals';
import { AssemblyNote } from './notes';
import { Part } from './part';
import { ToolTypes } from './tool-type-config';

export interface AssemblyComponent {
  componentNumber: number;
  componentName: string;
  part: Part;
  qty: number;
}

export interface AssemblySpecs {
  pressureRating: number;
  optimumFlowRate: number;
  maxCirculationTime: number;
  allowableMaxFlowRate: number;
}

export interface Assembly extends BaseData {
  assemblyNumber: string;
  toolType: string;
  toolClass: string;
  toolSeries: string;
  toolFamily: string;
  description: string;
  dimensionals: Dimensionals;
  od: number;
  id: number;
  weight: string;
  grade: string;
  material: string;
  topConnection: string;
  bottomConnection: string;
  notes: AssemblyNote[];
  currentDrawingUrl: string;
  deprecatedBoolean: boolean;
  assemblyComponents: AssemblyComponent[];
  assemblyCard: string;
  functionTestCard: string;
  masterForms: string;
  assemblySpecs: AssemblySpecs;
  status: string;
}

export class Assembly extends BaseData {
  constructor(data: Partial<Assembly>) {
    super(data);
    this.uid = data.assemblyNumber || null;
    this.assemblyNumber = data.assemblyNumber || null;
    this.toolType = data.toolType || null;
    this.toolClass = data.toolClass || null;
    this.toolSeries = data.toolSeries || null;
    this.toolFamily = data.toolFamily || null;
    this.description = data.description || null;
    this.dimensionals = data.dimensionals || null;
    this.od = data.od || null;
    this.id = data.id || null;
    this.weight = data.weight || null;
    this.grade = data.grade || null;
    this.material = data.material || null;
    this.topConnection = data.topConnection || null;
    this.bottomConnection = data.bottomConnection || null;
    this.notes = data.notes || null;
    this.currentDrawingUrl = data.currentDrawingUrl || null;
    //@ts-expect-error
    this.deprecatedBoolean = data.deprecatedBoolean === "TRUE" ? true : false || null;
    this.assemblyComponents = data.assemblyComponents || [];
    this.assemblyCard = data.assemblyCard || null;
    this.functionTestCard = data.functionTestCard || null;
    this.masterForms = data.masterForms || null;
    this.assemblySpecs = data.assemblySpecs || null;
    this.status = data.status || null;
    this.fromSpreadsheet(data);
  }
  /**
   * @deprecated This will be going away to utlize new version of 4_assembly spreadsheet.  components will become a object array of the assemblyComponent model.
   * use the static call fromSpreadsheet and toSpreadsheet for reading and writing.
   * @param  {} data
   * @param  {} {for(leti=1;i<=30;i++
   * @param  {} {constcomponentNumberKey=`comp${i}`;if(!data[componentNumberKey]
   * @param  {i} {return;}constdescriptionKey=`${componentNumberKey}Description`;constqtyKey=`${componentNumberKey}Qty`;constcomponent={componentNumber
   * @param  {data[descriptionKey]} description
   * @param  {newPart({partNumber:data[componentNumberKey]} part
   * @param  {} }
   * @param  {data[qtyKey]} qty
   * @param  {} };this.assemblyComponents.push(component
   */
  private createAssemblyComponents(data) {
    for (let i = 1; i <= 30; i++) {
      const componentNumberKey = `comp${i}`;
      if (!data[componentNumberKey]) { return; }
      const descriptionKey = `${componentNumberKey}Description`;
      const qtyKey = `${componentNumberKey}Qty`;
      const component = {
        componentNumber: i,
        componentName: data[descriptionKey],
        part: new Part({
          partNumber: data[componentNumberKey],
        }),
        qty: data[qtyKey],
      };
      this.assemblyComponents.push(component);
    }
  }

  private fromSpreadsheet(assembly: Partial<Assembly>) {
    //@ts-expect-error
    this.assemblyComponents = JSON.parse(assembly.assemblyComponents);
    //@ts-expect-error
    this.dimensionals = JSON.parse(assembly.dimensionals);
    //@ts-expect-error
    this.assemblySpecs = JSON.parse(assembly.assemblySpecs);
    //@ts-expect-error
    this.notes = JSON.parse(assembly.notes);
  }

  static toSpreadsheet(assembly: Assembly) {
    // @ts-expect-error
    assembly.assemblyComponents = JSON.stringify(assembly.assemblyComponents);
    //@ts-expect-error
    assembly.dimensionals = JSON.stringify(assembly.dimensionals);
    //@ts-expect-error
    assembly.assemblySpecs = JSON.stringify(assembly.assemblySpecs);
    //@ts-expect-error
    assembly.deprecatedBoolean = assembly.deprecatedBoolean ? 'TRUE' : 'FALSE';
    console.log(assembly);
    //@ts-expect-error
    assembly.notes = JSON.stringify(assembly.notes);

    return assembly;
  }
}

// export const assemblyConverter = {
//   toFirestore(assembly: Partial<Assembly>): firebase.firestore.DocumentData {
//     return { ...assembly };
//   },
//   fromFirestore(
//     snapshot: firebase.firestore.QueryDocumentSnapshot,
//     options: firebase.firestore.SnapshotOptions
//   ): Assembly {
//     const data = snapshot.data(options);
//     return new Assembly(data);
//   },
// };
