export class ShopEquipment {
    // Ids
    uid: string;
    name: string;
    serialNumber: string;
    modelNumber: string;
    manufacturer: string;
    calibrationCompany: string;
    // Dates
    currentCalibrationDate: Date;
    calibrationDuration: number;
    // Boolean
    failureBoolean: boolean;
    gettingCalibratedBoolean: boolean;
    inUseBoolean: boolean;
    // Drive Properties
    folderUrl: string;
    fileId: string;

    constructor(data: Partial<ShopEquipment>) {
        this.uid = data.uid;
        this.name = data.name;
        this.serialNumber = data.serialNumber || null;
        this.modelNumber = data.modelNumber || null;
        this.manufacturer = data.manufacturer || null;
        this.calibrationCompany = data.calibrationCompany || null;

        // Date comes from Firestore as a timestamp
        //@ts-expect-error
        this.currentCalibrationDate = data.currentCalibrationDate.toDate() || null;

        this.calibrationDuration = data.calibrationDuration || null;
        this.failureBoolean = data.failureBoolean || false;
        this.gettingCalibratedBoolean = data.gettingCalibratedBoolean || false;
        this.inUseBoolean = data.inUseBoolean || false;
        this.folderUrl = data.folderUrl || null;
        this.fileId = data.fileId || null;
    }

    get outOfDateBoolean() {

        if (this.calibrationDuration && this.currentCalibrationDate) {
            const nextDate = new Date(this.calibrationDueDate);
            return new Date().getTime() > nextDate.getTime();
        }
        return false;
    }

    get calibrationDueDate() {

        if (this.calibrationDuration && this.currentCalibrationDate) {
            const nextDate = new Date(this.currentCalibrationDate);
            nextDate.setMonth(nextDate.getMonth() + this.calibrationDuration);
            return nextDate;
        }
        return new Date();

    }

    get fileUrl() {

        if (this.fileId) {
            return `https://drive.google.com/file/d/${this.fileId}`;
        }
        return null;
    }

    static toSpreadsheet(equipment: ShopEquipment) {
        // //@ts-expect-error
        // equipment.currentCalibrationDate = equipment.currentCalibrationDate ? `${equipment.currentCalibrationDate}` : null;

        return equipment;
    }
}