import { GAPIService } from "@dis/gapi";
import { RefreshGapiTokenService } from "./refresh-gapi-access-token.service";

/**
 * @param  {GAPIService} gapiService
 */
export function appInitGapi(
  gapiService: GAPIService,
  refreshAccessTokenService: RefreshGapiTokenService,
) {
  const auth = JSON.parse(localStorage.getItem('auth'));
  const access_token = auth?.gapi?.access_token;

  return () => gapiService.initGapi()
    .then(async () => await gapiService.initClients()
      .then(async () => {
        // console.log('APP_INITIALIZER.refreshAccessToken.isExpired', refreshAccessTokenService.isExpired)
        if (auth && refreshAccessTokenService.isExpired) {
          await refreshAccessTokenService.refreshToken();
        } else {
          gapi.client.setToken({ access_token });
          refreshAccessTokenService.setRefreshTimer();
        }
      }))
    .then((result) => console.log('appInitGapi.Factory - finsihed', { result }));
}
