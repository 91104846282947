import { Injectable } from "@angular/core";
import { FirestoreV9Service } from "../../../services";
import { Invoice } from "@dis/models";
import { setDoc, doc, collection } from "@angular/fire/firestore";
import { map, switchMap, tap } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class InvoicesFirestore extends FirestoreV9Service<Invoice> {

  protected override basePath: string = 'invoices';

  protected override baseConverter = {
    fromFirestore: (
      snapshot: any,
      options: any,
    ) => {
      const data = new Invoice(snapshot.data(options));
      return data;
    },
    toFirestore: (values: Invoice) => values
  }

  async addInvoiceToFirestore(value: Invoice) {
    const docRef = doc(this.firestore, this.basePath, `${value.invoiceNumber}`);
    await setDoc(docRef, value);
  }
}