import { StateOperator } from '@ngxs/store';
import { updateItem, iif, Predicate, append, insertItem } from '@ngxs/store/operators';

export function upsertItem<T>(
  selector: Predicate<T>,
  upsertValue: T
): StateOperator<T[]> {
  return iif<T[]>(
    items => items.some(<any>selector),
    //@ts-expect-error
    <StateOperator<T[]>>updateItem(selector, upsertValue),
    //@ts-expect-error
    <StateOperator<T[]>>insertItem(upsertValue)
  )
}
