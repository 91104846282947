import { createPropertySelectors, createSelector } from "@ngxs/store";
import { BUILDS_STATE_TOKEN, BuildsStateModel } from "./state";

export class BuildsSelectors {
  static slices = createPropertySelectors<BuildsStateModel>(BUILDS_STATE_TOKEN);

  static getById(uid: string) {
    return createSelector([BUILDS_STATE_TOKEN], (state: BuildsStateModel) => {
      return state.items.filter(build => build.uid === uid)[0];
    })
  }

  static getByAssemblyNumber(assemblyNumber: string) {
    return createSelector([BUILDS_STATE_TOKEN], (state: BuildsStateModel) => {
      return state.items.filter(build => build.assemblyNumber === assemblyNumber);
    })
  }

  static getByBuildNumber(buildNumber: string) {
    return createSelector([BUILDS_STATE_TOKEN], (state: BuildsStateModel) => {
      return state.items.filter(build => build.buildNumber === buildNumber);
    })
  }

  static getByJobNumber(jobNumber: string) {
    return createSelector([BUILDS_STATE_TOKEN], (state: BuildsStateModel) => {
      return [...state.items?.filter(build => build.jobNumber === jobNumber)].reverse()
    })
  }

  static getByToolType(toolType: string) {
    return createSelector([BUILDS_STATE_TOKEN], (state: BuildsStateModel) => {
      return state.items.filter(build => build.toolType === toolType);
    })
  }


  static getDownHoleBuildsOfJob(jobNumber: string) {
    return createSelector([BUILDS_STATE_TOKEN], (state: BuildsStateModel) => {
      return state.items.filter(item => item.status === 'DOWNHOLE' && item.jobNumber === jobNumber).length > 0;
    })
  }
}
