import { Injectable } from "@angular/core";
import { FirestoreV9Service } from "../../services";
import { AppContext, AppContextCustomers, AppContextContainers, AppContextAddresses, AppContextTpi, AppContextToolLabels } from "@dis/models";
import { DocumentData, FirestoreDataConverter, arrayRemove, arrayUnion, doc, updateDoc } from "@angular/fire/firestore";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AppMetadataCollection extends FirestoreV9Service<AppContext> {
    protected override basePath = "appMetadata";
    protected override baseConverter: FirestoreDataConverter<AppContext, DocumentData>;

    customers$ = this.doc$('customers') as unknown as Observable<AppContextCustomers>;

    containers$ = this.doc$('containers') as unknown as Observable<AppContextContainers>;

    addresses$ = this.doc$('addresses') as unknown as Observable<AppContextAddresses>;

    tpi$ = this.doc$('tpi') as unknown as Observable<AppContextTpi>;

    toolLabels$ = this.doc$('toolLabels') as unknown as Observable<AppContextToolLabels>;

    updateToolLabelsOptionFeilds(feildName: string, value: string) {
        value = value.toUpperCase();
        return this.upsert(
            'toolLabels',
            {
                [feildName]: arrayUnion(value)
            }
        )
    }
}