import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SheetsApiService {

  get spreadsheet() {
    return gapi.client.sheets.spreadsheets.values;
  }

  /**
   * @param  {} spreadsheetId
   * @param  {} headerRange
   * @param  {{}} data
   */
  async appendDataRow(spreadsheetId, headerRange, data: {}) {
    const headers = await this.getRangeValues(spreadsheetId, headerRange);
    const values = [this.serializeToSheetDataFromJson(headers[0], data)];
    const response = await gapi.client.sheets.spreadsheets.values.append({
      spreadsheetId,
      valueInputOption: "USER_ENTERED",
      range: headerRange,
      resource: {
        values,
      },
    });
    return response.result;
  }

  async updateDataRow(spreadsheetId, headerRange, dataRange, data: {}) {
    const headers = await this.getRangeValues(spreadsheetId, headerRange);
    const values = [this.serializeToSheetDataFromJson(headers[0], data)];
    const response = await gapi.client.sheets.spreadsheets.values.update({
      spreadsheetId,
      valueInputOption: "USER_ENTERED",
      range: dataRange,
      resource: {
        values,
      },
    });
    return response.result;
  }

  /**
   *
   * @param spreadsheetId
   * @param sheet
   * @param headerRange
   * @param data
   * @returns
   */
  async batchUpdateSheetRows<T>(spreadsheetId, sheet, headerRange, data: T[]) {
    // Always getting latest headers to ensure none have moved columns.
    const headers = await this.getRangeValues(spreadsheetId, headerRange);
    const dataToWrite: gapi.client.sheets.ValueRange[] = [];

    data.map((row: any) => dataToWrite.push({
      majorDimension: 'ROWS',
      range: `${sheet}!A${row.rowIndex}:${row.rowIndex}`,
      values: [this.serializeToSheetDataFromJson(headers[0], row)],
    }));

    const response = await gapi.client.sheets.spreadsheets.values.batchUpdate({
      spreadsheetId,
      resource: {
        valueInputOption: "USER_ENTERED",
        data: dataToWrite,
      }
    });

    return response.result;
  }


  async getRangeValues(spreadsheetId: string, range: string): Promise<any[][]> {
    const response = await gapi.client.sheets.spreadsheets.values.get({
      spreadsheetId,
      range,
    });
    return response.result.values;
  }

  async getHeaderAndRowValues(spreadsheetId: string, headerRange: string, dataRange: string): Promise<any[][]> {
    const response = await gapi.client.sheets.spreadsheets.values.batchGet({
      spreadsheetId,
      ranges: [
        headerRange,
        dataRange
      ]
    });
    const result = response.result.valueRanges;
    return [result[0].values[0], result[1].values];
  }

  /** @deprecated going to use getRangeValues and serailize in sheets-store.service */
  async getDataRange<T>(spreadsheetId: string, range: string): Promise<any> {
    return await gapi.client.sheets.spreadsheets.values.get({
      spreadsheetId,
      range
    })
      .then(result => this.serializeCollectionFromSheetDataToJson(result.result.values))
      .catch(console.error)
  }

  /** helpers */
  serializeToSheetDataFromJson<T>(headers: string[], data: T) {
    return headers.map(header => data[header.toString()]);
  }

  private serializeCollectionFromSheetDataToJson(data: any[][]) {
    const headers = data.shift();
    return data.map((row, index) => {
      return headers.reduce((accumulator, currentValue, currentIndex) => {
        accumulator[currentValue.toString().trim()] = row[currentIndex];
        return accumulator;
      }, {
        rowIndex: index + 2, // This allows us to start at row2
      });
    });
  }

}
