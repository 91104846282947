
import { AppUser } from "../models/user";
import { AuthStateModel } from "./auth.state";

export namespace AuthActions {
  export class Claims {
    static readonly type = '[AUTH] Set Claims';
    constructor(public payload: any) { }
  }

  export class GetUser {
    static readonly type = '[AUTH] Get User';
  }

  export class Loading {
    static readonly type = '[AUTH] Set Loading';
    constructor(public payload: boolean) { }
  }
  export class Login {
    static readonly type = '[AUTH] Login';
    constructor(public payload: { email: string; password: string }) { }
  }

  export class Signin {
    static readonly type = '[AUTH] Signin';
  }

  export class Signout {
    static readonly type = '[AUTH] Signout';
    constructor(public payload: any) { }
  }

  export class Signup {
    static readonly type = '[AUTH] Signup';
    constructor(public payload: { email: string; password: string }) { }
  }

  export class SetUser {
    static readonly type = '[AUTH] Set User';
    constructor(public payload: AppUser) { }
  }

  export class Update {
    static readonly type = '[AUTH] Update auth';
    constructor(public payload: Partial<AuthStateModel>) { }
  }

}
