import { Injectable } from "@angular/core";
import { Container as Model } from "@dis/models";
import { FirestoreDataConverter, DocumentData } from "@firebase/firestore";
import { NgxsFirestore } from "@ngxs-labs/firestore-plugin";

@Injectable({
  providedIn: 'root'
})
export class ContainersFirestore extends NgxsFirestore<Model> {
  protected path = 'containers';
  override idField = 'uid';

  protected override converter: FirestoreDataConverter<Model, DocumentData> = {
    toFirestore: (items: Model): DocumentData => {
      return {
        ...items,
      };
    },
    fromFirestore: (
      snapshot,
      options,
    ): Model => {
      const data = snapshot.data(options);
      return new Model(data);
    },
  }
}
