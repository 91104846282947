import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Injectable } from "@angular/core";
import { tap } from "rxjs";
import { Action, NgxsAfterBootstrap, Selector, State, StateContext, StateToken } from "@ngxs/store";
import { LayoutStateActions } from "./layout.actions";

interface StateModel {
  isHandset: boolean;
}

const STATE_NAME = 'layoutState';
const STATE_TOKEN = new StateToken<StateModel>(STATE_NAME);

export {
  StateModel as LayoutStateModel,
  STATE_TOKEN as LAYOUT_STATE_TOKEN,
}

@State({
  name: STATE_TOKEN,
})
@Injectable()
export class LayoutState implements NgxsAfterBootstrap {

  @Selector([STATE_TOKEN])
  static isHandset(state: StateModel) {
    return state.isHandset;
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
  ) { }

  ngxsAfterBootstrap({ dispatch }: StateContext<StateModel>) {
    this.breakpointObserver.observe([Breakpoints.Handset])
      .pipe(
        tap(result => dispatch(new LayoutStateActions.SetHandset(result.matches))),
      ).subscribe();
  }

  @Action(LayoutStateActions.SetHandset)
  setHandset({ patchState }: StateContext<StateModel>, { payload }: LayoutStateActions.SetHandset) {
    patchState({
      isHandset: payload,
    })
  }
}
