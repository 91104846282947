import { BaseData } from "./_global";
import { BillingCode } from "./job-billing";

export interface Quote {
    quoteNumber: string | null;
    runNumber: string | null;
    projectNumber: string | null;
    well: string | null;
    representative: string | null;
    description: string | null;
    status: string | null;
    notes: string | null;
    estDate: string | null;
    billing: QuoteBilling | null;
}

export class Quote extends BaseData {
    constructor(data: Partial<Quote>) {
        super(data);
        this.uid = data.quoteNumber || null;
        this.quoteNumber = data.quoteNumber || null;
        this.runNumber = data.runNumber || null;
        this.projectNumber = data.projectNumber || null;
        this.well = data.well || null;
        this.representative = data.representative || null;
        this.description = data.description || null;
        this.status = data.status || null;
        this.notes = data.notes || null;
        this.estDate = data.estDate || null;
        this.billing = data.billing || null;

        this.fromSpreadsheet(data);
    }

    private fromSpreadsheet(quote: Partial<Quote>) {

        if (typeof quote.billing === 'string') {
            this.billing = JSON.parse(quote.billing);
        }
    }

    static toSpreadsheet(quote: Quote) {
        //@ts-expect-error
        quote.billing = JSON.stringify(quote.billing);
        return quote;
    }
}

export interface QuoteBilling {
    customer: string;
    billingCode: BillingCode;
    otherBillingReferences: BillingCode[];
}
