import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { Store } from '@ngxs/store';
import { Observable, map, tap } from 'rxjs';

export class TableDataSource<T> extends DataSource<T> {
  readonly items$: Observable<T[]>;
  readonly count$: Observable<number>;

  constructor(
    private itemsSelector: any,
    private store: Store,
  ) {
    super();
    this.items$ = this.store.select(this.itemsSelector);
    this.count$ = this.items$.pipe(map(items => items.length));
  }

  override connect(collectionViewer: CollectionViewer): Observable<readonly T[]> {
    // console.log({ collectionViewer })
    return this.items$
  }

  override disconnect(collectionViewer: CollectionViewer): void {
    // console.log({ collectionViewer })
  }
}
