import { Injectable } from "@angular/core";
import { FirestoreV9Service } from "../../services";
import { doc, setDoc } from "@angular/fire/firestore";
import { Address } from "@dis/models";

@Injectable({
  providedIn: 'root'
})
export class AddressesCollection extends FirestoreV9Service<Address> {
  protected override basePath = "addresses";
  protected override baseConverter = {
    fromFirestore: (
      snapshot: any,
      options: any,
    ) => {
      const data = new Address(snapshot.data(options));
      return data;
    },
    toFirestore: (values: Address) => values
  }


  async createAddress(value: Address) {
    const docRef = doc(this.firestore, 'addresses', value.uid);
    await setDoc(docRef, value);
  }

  async updateAddress(value: Address) {
    this.upsert(value.uid, value);
  }


}