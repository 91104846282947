import { Container } from "./container";
import { Build } from "./build";
import { Tool } from "./tool";
import { Address } from "./addresses";

export interface ComponentShipment {
  jobNumber: string;
  projectNumber: string;
  buildNumber: string;
  status: string;
}

export class ComponentShipment {
  constructor(data: Partial<ComponentShipment> = {},) {
      // Date Array Fields
      this.jobNumber = data.jobNumber ? data.jobNumber : null;
      this.projectNumber = data.projectNumber ? data.projectNumber : null;
      this.buildNumber = data.buildNumber ? data.buildNumber : null;
      this.status = data.status ? data.status : null;
  }
}

// export interface Address {
//   title: string;
//   addressOne: string;
//   addressTwo: string;
//   city: string;
//   state: string;
//   postalCode: number;
// }
