
import { DocumentData, QueryDocumentSnapshot, SnapshotOptions } from '@angular/fire/firestore';
import { BaseData } from './_global';

export interface Part extends BaseData {
  partNumber;
  toolType;
  toolClass: string;
  toolSeries: string;
  toolFamily: string;
  description;
  od;
  id; // This is not the Firestore document ID it is a measurement for size of part
  wt;
  weight;
  grade;
  workingLength;
  overallLength;
  lengthClosed;
  lengthOpen;
  topConnection;
  bottomConnection;
  material;
  assemblyBoolean;
  deprecatedBoolean;
  disDesignedBoolean;
  notes;
  stockLevel_Func: number;
  status;
  inventoryType: string;
  drawingNumberOverride:string;
  reOrderPoint: number;
}

export class Part extends BaseData {
  constructor(data: Partial<Part>) {
    super(data);
    this.uid = data.partNumber || null;
    this.partNumber = data.partNumber || null;
    this.toolType = data.toolType || null;
    this.toolClass = data.toolClass || null;
    this.toolSeries = data.toolSeries || null;
    this.toolFamily = data.toolFamily || null;
    this.description = data.description || null;
    this.od = data.od || null;
    this.id = data.id || null;
    this.wt = data.wt || null;
    this.weight = data.weight || null;
    this.grade = data.grade || null;
    this.workingLength = data.workingLength || null;
    this.overallLength = data.overallLength || null;
    this.lengthClosed = data.lengthClosed || null;
    this.lengthOpen = data.lengthOpen || null;
    this.topConnection = data.topConnection || null;
    this.bottomConnection = data.bottomConnection || null;
    this.material = data.material || null;
    this.assemblyBoolean = data.assemblyBoolean || null;
    this.deprecatedBoolean = data.deprecatedBoolean || null;
    this.disDesignedBoolean = data.disDesignedBoolean || null;
    this.notes = data.notes || null;
    this.stockLevel_Func = data.stockLevel_Func || null;
    this.reOrderPoint = data.reOrderPoint || null;
    this.status = data.status || null;
    this.inventoryType = data.inventoryType || null;
    this.drawingNumberOverride = data.drawingNumberOverride || null;
  }
}

export const partConverter = {
  toFirestore(part: Part): DocumentData {
    return { ...part };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Part {
    const data = snapshot.data(options);
    return new Part(data);
  }
};
