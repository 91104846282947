import { Observable, Subject } from "rxjs";
import { FirestoreTableDataSource } from "./firestore-tables.datasource";
import { createDispatchMap, Store } from "@ngxs/store";
import { inject, TrackByFunction } from "@angular/core";

import { TableStateActions } from "./state/table.actions";
import { Sort } from "@angular/material/sort";

export abstract class JtmsFirestoreTable<T> {
  protected ngDestroy$ = new Subject();

  protected dataSource: FirestoreTableDataSource<T>;

  protected loading$: Observable<boolean>;

  protected showActionButtons: string;

  protected store: Store = inject(Store);

  protected tableName!: string;

  private actions = createDispatchMap({
    updateTableState: TableStateActions.UpdateState,
    updateTableSort: TableStateActions.UpdateSort,
  })

  protected sortChange(event: Sort) {
    this.actions.updateTableSort(this.tableName, event);
  }

  protected trackById(index: number, item: any): TrackByFunction<T> {
    return item.uid;
  }
}
