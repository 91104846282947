import { Component, OnInit, Input } from '@angular/core';
import { GoogleDriveApiService, FileInfo } from '@dis/gapi';

@Component({
  selector: 'shared-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.css']
})
export class FileListComponent implements OnInit {
  @Input() documents!: FileInfo[];

  constructor(
    private driveApi: GoogleDriveApiService,
  ) { }

  ngOnInit() {
  }

  async handleDocumentDelete(fileId: string, index: number) {
    await this.driveApi.delete(fileId);
    this.documents.splice(index, 1);
  }

}
