import { Injectable } from '@angular/core';
import { SheetsStoreService } from '../../../services';
import { COLLECTIONS, DBSHEET, Job, SSID } from '@dis/models'


@Injectable({
  providedIn: 'root'
})
export class JobsSheetStore extends SheetsStoreService<Job> {

  protected collectionName = COLLECTIONS.JOBS;

}
