import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(
    private meta: Meta,
    private title: Title,
  ) { }

  baseUrl() {
    return 'https://jtms.drillingllc.com';
  }

  generateTags(config: any) {
    // default values
    config = {
      title: 'JTMS',
      description: 'Job and Tool Management System',
      image: `${this.baseUrl()}/assets/icons/icon-72x72.png`,
      slug: '',
      ...config
    };

    this.title.setTitle(config.title);

    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'og:site_name', content: config.title });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:url', content: `${this.baseUrl()}/${config.slug}` });
  }
}
