import { Observable, Subject } from "rxjs";
import { Store } from "@ngxs/store";

import { TableDataSource } from "./tables.datasource";
import { TableStateActions } from "./state/table.actions";

import { FileInfo } from "@dis/gapi";
import { TrackByFunction, inject } from "@angular/core";

export abstract class JtmsTable<T> {
  protected ngDestroy$ = new Subject();

  protected dataSource: TableDataSource<T>;

  protected files: FileInfo[]

  protected loading$: Observable<boolean>;

  protected showActionButtons: string;

  protected store: Store = inject(Store);

  protected tableName: string;

  // TODO: Implement pagnation here also
  protected sortChange(sort: any) {
    this.store.dispatch(new TableStateActions.UpdateState(this.tableName, { ...sort }))
  }

  protected trackById(index: any, item: any): TrackByFunction<T> {
    return item.uid;
  }

}
