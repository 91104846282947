import { Timestamp } from '@angular/fire/firestore';
import { FileInfo } from '@dis/gapi';
import { convertToFirestoreTimestamp } from './_global';

export class Inspection {
  uid: string;
  inspectionId: string;
  date: Timestamp;
  fileId: string;
  fileUrl: string;

  static fromFile(file: FileInfo): Inspection {
    const inspection = new Inspection();
    inspection.inspectionId = file.Properties.description || null;
    inspection.date = convertToFirestoreTimestamp(file.Properties.documentDate) || null;
    inspection.fileId = file.Id || null;
    inspection.fileUrl = file.Url || null;
    return inspection;
  }
}
