import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Auth } from '@angular/fire/auth';

import { AuthActions } from './auth.actions';
import { AppUser } from '../models';

export interface AuthStateModel {
  loading: boolean;
  gapi: {
    access_token?: string;
    expiresAt: any;
  };
  qbo: {
    expiresAt: any;
  }
  claims: any;
  user: AppUser;
}

export const AUTH_STATE_TOKEN = new StateToken<AuthStateModel>('auth');

@State({
  name: AUTH_STATE_TOKEN,
  defaults: {
    loading: true,
    gapi: {
      expiresAt: null,
    },
    qbo: {
      expiresAt: null,
    },
    claims: null,
    user: null,
  }
})
@Injectable()
export class AuthState {
  @Selector()
  public static loading(state: AuthStateModel): boolean {
    return state.loading;
  }

  @Selector()
  public static allowedTenants(state: AuthStateModel): any {
    return state.claims?.tenants;
  }

  @Selector()
  public static claims(state: AuthStateModel): any {
    return state.claims;
  }

  @Selector()
  public static gapiTokens(state: AuthStateModel): { access_token?: string; expiresAt: any; } {
    return state.gapi;
  }

  @Selector()
  public static hasGapiToken(state: AuthStateModel): boolean {
    console.log('AuthState.hasGapiToken.ran', { state })
    return !!state?.gapi?.access_token;
  }

  @Selector()
  public static qboTokens(state: AuthStateModel): { expiresAt: Date; } {
    return state.qbo;
  }

  @Selector()
  public static user(state: AuthStateModel): AppUser {
    return state.user;
  }

  constructor(
    private afAuth: Auth,
  ) { }

  // @Lenward why are you returning only this and not affcting the state.
  @Action(AuthActions.GetUser)
  getUser({ patchState }: StateContext<AuthStateModel>, action: AuthActions.GetUser) {
    return this.afAuth.currentUser;
  }

  @Action(AuthActions.Signout)
  async signout({ dispatch, setState }: StateContext<AuthStateModel>, { payload }: AuthActions.Signout) {
    await payload
      .then(() => {
        setState({
          loading: false,
          gapi: null,
          qbo: null,
          claims: null,
          user: null,
        });
      });
    // dispatch(new Navigate(['signin']))
  }

  @Action(AuthActions.Update)
  updateAuth(ctx: StateContext<AuthStateModel>, { payload }: AuthActions.Update) {
    ctx.patchState({
      ...payload
    })
  }

  @Action(AuthActions.Claims)
  setClaims({ patchState }: StateContext<AuthStateModel>, { payload }: AuthActions.Claims): void {
    patchState({
      loading: false,
      claims: payload
    });
  }

  @Action(AuthActions.Loading)
  setLoading({ patchState }: StateContext<AuthStateModel>, { payload }: AuthActions.Loading): void {
    patchState({ loading: payload });
  }

  @Action(AuthActions.SetUser)
  setUser({ patchState }: StateContext<AuthStateModel>, { payload }: AuthActions.SetUser): void {
    patchState({
      loading: false,
      user: payload
    });
  }

}
