

export interface AssemblyNote {
    note: string;
    dateCreated: Date;
    dateUpdated: Date;
    type: 'GENERAL' | 'SPECSHEET';
}

export class AssemblyNote {
    constructor(data: Partial<AssemblyNote> = {},) {
        this.note = data.note || null;
        this.dateCreated = data.dateCreated ? new Date(data.dateCreated) : null;
        this.dateUpdated = data.dateUpdated ? new Date(data.dateUpdated) : null;
        this.type = data.type || null;
    }
}
