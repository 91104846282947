import { Part as Model } from "@dis/models";
const title = 'PARTS';
export { StateActions as PartsStateActions }

namespace StateActions {
  export class Delete {
    public static readonly type = `[${title}] Delete`;
    constructor(public payload: Model) { }
  }
  export class GetAll {
    public static readonly type = `[${title}] Get all`;
  }

  export class GetQuery {
    public static readonly type = `[${title}] Get query`;
  }

  export class FilterList {
    public static readonly type = `[${title}] Filter list`;
    constructor(public payload: string) { }
  }

  export class StartCollectionSheetWatcher {
    public static readonly type = `[${title}] Start Collection Sheet Watcher`;
  }

  export class UpdateFromSheetWatcher {
    public static readonly type = `[${title}] Update from Sheet Watcher`;
    constructor(public payload: Model[]) { }
  }

  export class Update {
    public static readonly type = `[${title}] Update`;
    constructor(public payload: Model) { }
  }
}
