export class TestParams {
    name: string;
    notes: string;
    instructions: string[];
    step: number;
    testConfig: TestConfig;
    testNumberParameter: number[];

    constructor(data: any) {
        this.name = data.name || null;
        this.notes = data.notes || null;
        this.instructions = data.instructions || null;
        this.step = data.step || null;
        this.testConfig = data.testConfig || null;
        this.testNumberParameter = data.testNumberParameter || null;
    }
}

export enum TestConfig {
    OPERATIONTEST = 'operationTest',
    PRESSURETEST = 'pressureTest',
}

export interface FunctionTestParameters {
    functionTestName: string;
    functionTestParams: TestParams[];
    numberOfSteps: number;
}
