import { Injectable } from '@angular/core';
import { SheetsStoreService } from '../../../services';
import { COLLECTIONS, Component as JtmsComponent} from '@dis/models'


@Injectable({
  providedIn: 'root'
})
export class ComponentsSheetStore extends SheetsStoreService<JtmsComponent> {

  protected collectionName = COLLECTIONS.COMPONENTS;
  protected override collectionRows = '1:2000'

}
