export enum ToolTypes {
  BPV = 'BPV',
  CRC = 'CRC',
  CRCIS = 'CRCIS',
  CRCPA = 'CRCPA',
  DSFV = 'DSFV',
  DSFVLR = 'DSFVLR',
  ESJ = 'ESJ',
  SSUB = 'SSUB',
  XO = 'XO',
  VSV = 'VSV',
}

export const ToolConfig = {
  DSFV: ['213', '313'],
}
