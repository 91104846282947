export interface IGeometry {
  type: string;
  coordinates: number[];
}

export interface IGeoJson<T> {
  type: string;
  geometry: IGeometry;
  properties?: T;
  $key?: string;
}

export class GeoJson<T> implements IGeoJson<T> {
  type = 'Feature';
  geometry: IGeometry;

  constructor(coordinates: number[], public properties?: T) {
    this.geometry = {
      type: 'Point',
      coordinates: coordinates
    }
  }
}

export class FeatureCollection<T> {
  type = 'FeatureCollection'
  constructor(public features: Array<GeoJson<T>>) { }
}
