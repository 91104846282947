import { Injectable } from "@angular/core";

const SERVICE_ENDPOINT = "https://chat.googleapis.com";

const REQUEST: gapi.client.chat.SetUpSpaceRequest = {};

type SpaceName = `spaces/${string}`;

@Injectable({
  providedIn: 'root',
})
export class GoogleChatApiService {
  private readonly spaces = gapi.client.chat.spaces;
  private readonly members = gapi.client.chat.spaces.members;
  private readonly messages = gapi.client.chat.spaces.messages;

  readonly Spaces = {
    create: async (displayName: string) => {
      const response = await this.spaces.create({
        resource: {
          displayName,
          spaceType: "SPACE"
        }
      })
      return response.result;
    },
    get: async (name: SpaceName) => {
      const response = await this.spaces.get({
        name,
      });
      return response.result;
    }
  }

  readonly Messages = {
    get: async (message: string) => {
      const response = await this.messages.get({
        name: message,
      });
      return response.result;
    },
    list: async (parent: SpaceName) => {
      const response = await this.messages.list({
        parent,
      });
      return response.result;
    }
  }




}
