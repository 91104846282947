import { Injectable } from '@angular/core';
import { SheetsStoreService } from '../../../services';
import { COLLECTIONS, Quote } from '@dis/models'

@Injectable({
  providedIn: 'root'
})
export class QuotesSheetStore extends SheetsStoreService<Quote> {

  protected collectionName = COLLECTIONS.QUOTES;

}
