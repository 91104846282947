<mat-form-field class="filter" style="width:100%">
  <input #filter matInput
   [formControl]="filterString"
   [value]="filterString.value | uppercase"
   type="search"
   [placeholder]="placeHolderText()"
   autocomplete="off">
  <button mat-icon-button matSuffix (click)="openScannerDialog()" [attr.aria-label]="'Barcode Scanner'">
    <mat-icon>qr_code_scanner</mat-icon>
  </button>
</mat-form-field>
