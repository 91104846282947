import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';

import { AuthRoutingModule } from './auth-routing.module';

import { LoginComponent } from './login/login.component';
import { SignoutComponent } from './signout/signout.component';
import { CanDeactivateDialogComponent } from './can-deactivate-dialog/can-deactivate-dialog.component';

@NgModule({
  declarations: [
    LoginComponent,
    SignoutComponent,
    CanDeactivateDialogComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
  ],
  exports: [
    LoginComponent,
    SignoutComponent,
    CanDeactivateDialogComponent,
  ]
})
export class AuthModule { }
