/// <reference path="../../../../../node_modules/@types/gapi/index.d.ts" />
/// <reference path="../../../../../node_modules/@types/gapi.client.discovery-v1/index.d.ts" />
/// <reference path="../../../../../node_modules/@types/gapi.client.drive-v3/index.d.ts" />
/// <reference path="../../../../../node_modules/@types/gapi.client.script-v1/index.d.ts" />
/// <reference path="../../../../../node_modules/@types/gapi.client.sheets-v4/index.d.ts" />
/// <reference path="../../../../../node_modules/@types/gapi.client.chat-v1/index.d.ts" />

import { Injectable } from '@angular/core';

export const SCOPES = [
  'https://www.googleapis.com/auth/chat.spaces',
  'https://www.googleapis.com/auth/chat.memberships',
  'https://www.googleapis.com/auth/chat.messages',
  'https://www.googleapis.com/auth/documents',
  'https://www.googleapis.com/auth/drive',
  'https://www.googleapis.com/auth/drive.file',
  'https://www.googleapis.com/auth/drive.metadata',
  'https://www.googleapis.com/auth/drive.appdata',
  'https://www.googleapis.com/auth/script.container.ui',
  'https://www.googleapis.com/auth/script.external_request',
  'https://www.googleapis.com/auth/script.projects',
  "https://www.googleapis.com/auth/script.scriptapp",
  "https://www.googleapis.com/auth/script.send_mail",
  'https://www.googleapis.com/auth/spreadsheets',
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile',
];

declare const gapi: any;

@Injectable({
  providedIn: 'root'
})
export class GAPIService {

  async initGapi() {
    console.log('GAPIService.initGapi.called');
    return new Promise((resolve, reject) => {
      gapi.load('client', () => {
        gapi.client.init({
          hosted_domain: "drillingllc.com",
        }).then(() => {
          resolve(gapi.client);
        }).catch((error) => {
          reject(JSON.stringify(error));
        })
      })
    })
  }

  async initClients() {
    console.log('GAPIService.initClients.called');

    return Promise.all([
      await gapi.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'),
      await gapi.client.load('https://script.googleapis.com/$discovery/rest?version=v1'),
      await gapi.client.load('https://sheets.googleapis.com/$discovery/rest?version=v4'),
      await gapi.client.load('https://chat.googleapis.com/$discovery/rest?version=v1'),
    ])
    .then(result => console.log(`GAPIService.initClients.Promise.all.gapi.client.loads`, {result}))
  }

}
