import { Injectable } from '@angular/core';
import { SheetsStoreService } from '../../../services';
import { COLLECTIONS, Project } from '@dis/models'

@Injectable({
  providedIn: 'root'
})
export class ProjectsSheetStore extends SheetsStoreService<Project> {

  protected collectionName = COLLECTIONS.PROJECTS;

}
