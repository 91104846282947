import { Injectable } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";

import { Action, State, StateContext, StateToken, createSelector } from "@ngxs/store";

import { TableStateActions as StateActions } from "./table.actions";


export interface TableQuery {
  filterText?: string;
  active?: string;
  direction?: 'asc' | 'desc';
  page?: PageEvent;
  sort?: Sort,
  queryCount?: number;
  startAfterId?: any;
}

interface StateModel {
  [tableName: string]: TableQuery;
}

const STATE_NAME = 'table';
const STATE_TOKEN = new StateToken<StateModel>(`${STATE_NAME}State`);

export {
  StateModel as TableStateModel,
  STATE_TOKEN as TABLE_STATE_TOKEN
}

const defaults = {
  filterText: null,
  // active: null,
  // direction: null,
  page: {
    pageIndex: 0,
    pageSize: 0,
    length: 0
  },
  sort: {
    active: null,
    direction: null
  },
}

@State({
  name: STATE_TOKEN,
  // defaults: {
  //   [COLLECTIONS.QUOTES]: {
  //     ...defaults
  //   },
  //   [COLLECTIONS.PROJECTS]: {
  //     ...defaults
  //   },
  //   [COLLECTIONS.JOBS]: {
  //     ...defaults
  //   },
  //   [COLLECTIONS.BUILDS]: {
  //     ...defaults
  //   },
  //   [COLLECTIONS.ASSEMBLIES]: {
  //     ...defaults
  //   },
  //   [COLLECTIONS.COMPONENTS]: {
  //     ...defaults
  //   },
  //   [COLLECTIONS.PARTS]: {
  //     ...defaults
  //   },
  //   [COLLECTIONS.CONTAINERS]: {
  //     ...defaults
  //   },
  //   [COLLECTIONS.INVOICES]: {
  //     ...defaults
  //   },
  //   [COLLECTIONS.EQUIPMENT]: {
  //     ...defaults
  //   },
  // }
})
@Injectable()
export class TableState {

  static filterText(table: string) {
    return createSelector([STATE_TOKEN], (state: StateModel) => {
      return state[table]?.filterText;
    })
  }

  static page(table: string) {
    return createSelector([STATE_TOKEN], (state: StateModel) => {
      return state[table]?.page;
    })
  }

  static pageIndexSize(table: string) {
    return createSelector([STATE_TOKEN, TableState.page], (state: StateModel, page: PageEvent) => {
      const {pageIndex, pageSize} = page;
      return {pageIndex, pageSize};
    })
  }

  static queryCount(table: string) {
    return createSelector([STATE_TOKEN], (state: StateModel) => {
      return state[table]?.queryCount || 2000;
    })
  }

  static sort(table: string) {
    return createSelector([STATE_TOKEN], (state: StateModel) => {
      return state[table]?.sort;
    })
  }

  static startAfterId(table: string) {
    return createSelector([STATE_TOKEN], (state: StateModel) => {
      return state[table]?.startAfterId;
    })
  }

  static tableQuery(table: string) {
    return createSelector([STATE_TOKEN], (state: StateModel) => {
      return state[table];
    })
  }


  // TODO: may reimpliment this below to ensure tables exist,
  // but because of using storage to many tables endup not used.

  // ngxsAfterBootstrap(ctx: StateContext<any>): void {
  //   Object.keys(COLLECTIONS)
  //   .filter(key => isNaN(Number(key)))
  //   .forEach(collection => {
  //     ctx.dispatch(new StateActions.UpdateState(collection, defaults));
  //   })
  // }

  @Action(StateActions.UpdateFilter)
  updateFilter({ patchState, getState }: StateContext<StateModel>, { tableName, payload }: StateActions.UpdateFilter) {
    const state = getState();
    patchState({
      [tableName]: {
        ...state[tableName],
        filterText: payload,
        page: {
          ...state[tableName].page,
          pageIndex: 0
        }
      }
    })
  }


  /**
   * @description handles pagination from collections wrapper table
   * @param param0
   * @param param1
   */
  @Action(StateActions.UpdatePage)
  updatePage({ patchState, getState }: StateContext<StateModel>, { tableName, payload }: StateActions.UpdatePage) {
    const state = getState();
    patchState({
      [tableName]: {
        ...state[tableName],
        page: payload
      }
    })
  }

  /**
   * @description handles sorting from collections wrapper table
   * @param param0
   * @param param1
   */
  @Action(StateActions.UpdateSort)
  updateSort({ patchState, getState }: StateContext<StateModel>, { tableName, payload }: StateActions.UpdateSort) {
    const state = getState();
    patchState({
      [tableName]: {
        ...state[tableName],
        sort: payload,
        page: {
          ...state[tableName].page,
          pageIndex: 0
        }
      }
    })
  }

  /**
   * @description handles updating any other table query from collections wrapper table and filters
   * @param param0
   * @param param1
   */
  @Action(StateActions.UpdateState)
  updateTableQuery({ getState, patchState, setState  }: StateContext<StateModel>, { tableName, payload }: StateActions.UpdateState) {
    const state = getState();
    setState({
      ...state,
      [tableName]: {
        ...state[tableName],
        ...payload,
      }
    })
  }
}
