import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, tap } from "rxjs";

import { TableState } from "./state/table.state";

import { createDispatchMap, createSelectMap, select, Store } from "@ngxs/store";
import { effect, inject, untracked } from "@angular/core";

import { toObservable } from '@angular/core/rxjs-interop';
import { Disconnect } from "@ngxs-labs/firestore-plugin";
import { limit, orderBy, OrderByDirection, QueryConstraint, startAfter, where } from "@angular/fire/firestore";
import { TableStateActions } from "./state/table.actions";

export class FirestoreTableDataSource<T> extends DataSource<T> {

  private constraints = createSelectMap({
    filterText: TableState.filterText(this.tableName),
    sort: TableState.sort(this.tableName),
    page: TableState.page(this.tableName),
    queryCount: TableState.queryCount(this.tableName),
    startAfterId: TableState.startAfterId(this.tableName),
  });

  private actions = createDispatchMap({
    getFirestoreItems: this.stateActions.Firestore.GetQuery,
    updateTableConstraints: TableStateActions.UpdateState,
  });

  private store = inject(Store);

  private items = select<T[]>(this.state.firestoreItems);

  constructor(
    private state: any,
    private stateActions: any,
    private tableName: string,
    // @Optional() private baseQueryConstraints: QueryConstraint[] = [],
  ) {
    super();

    // Create query
    effect((onCleanup) => {
      const sort = this.constraints.sort();
      const page = this.constraints.page();
      const filterText = this.constraints.filterText();

      const queryConstraints: QueryConstraint[] = [];

      untracked(async () => {

        if (sort?.active && sort?.direction) {
          queryConstraints.push(orderBy(
            sort.active,
            sort.direction as OrderByDirection,
          ))
        } else if (sort?.active) {
          queryConstraints.push(orderBy(
            sort.active
          ))
        }

        if (sort?.active && filterText?.length > 3) {
          queryConstraints.push(
            where(sort.active, ">=", filterText),
            where(sort.active, "<=", `${filterText}\uf8ff`)
          )
        }

        if (page?.pageIndex > 0 && sort?.active) {
          queryConstraints.push(startAfter(this.constraints.startAfterId()));
        }

        queryConstraints.push(limit(page?.pageSize || 1));

        console.log("queryConstraints", queryConstraints);

        this.actions.getFirestoreItems(
          queryConstraints
        )

      });

      onCleanup(() => {
        this.store.dispatch(new Disconnect(new this.stateActions.Firestore.GetQuery([])));
      });
    });
  }


  // TODO: feel like this should really be part of the ngxsFirestore state query constructors
  // private buildCountAggregation(queryConstraints: QueryConstraint[]) {
  //   const colRef = collection(this.firestore, this.tableName);
  //   const q = query(colRef, ...queryConstraints);
  //   return getCountFromServer(q);
  // }


  override connect(collectionViewer: CollectionViewer): Observable<readonly T[]> {
    // console.log("connect", { collectionViewer })

    return toObservable(this.items)
      .pipe(
        tap(items => {
          const sort = this.constraints.sort();
          const lastItem = items[items.length - 1];
          if (lastItem && sort?.active) {
            this.actions.updateTableConstraints(this.tableName, {
              startAfterId: lastItem[sort.active],
            });
          }
        })
      ) as Observable<readonly T[]>;
  }

  override disconnect(collectionViewer: CollectionViewer): void {
    console.log("disconnect", { collectionViewer })
    this.store.dispatch(new Disconnect(new this.stateActions.Firestore.GetQuery([])));
  }
}
