<mat-expansion-panel style="width: 100%;">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Raw data
    </mat-panel-title>
  </mat-expansion-panel-header>

  <pre>{{ data | json }}</pre>

</mat-expansion-panel>
