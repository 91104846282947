import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SheetsMetadata } from '@dis/models'

import { SSID } from '@dis/models'
import { Firestore, doc, docData, updateDoc } from '@angular/fire/firestore';

const COLLECTION = "sheets-metadata";

@Injectable({
  providedIn: 'root'
})
export class SheetsMetadataService {

  constructor(
    private firestore: Firestore,
  ) { }

  watchSheetMetadata(SpreadsheetId: SSID): Observable<SheetsMetadata> {
    return docData(doc(this.firestore, COLLECTION, SpreadsheetId)) as Observable<SheetsMetadata>;
  }

  updateSheetMetadata(SpreadsheetId: SSID, metadata: Partial<SheetsMetadata>) {
    return updateDoc(doc(this.firestore, COLLECTION, SpreadsheetId), {
      ...metadata,
      action_type: 'SHEETS_STORE',
      updatedAt: new Date().getTime(),
    })
  }
}
