import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom, map, Observable } from 'rxjs';
import { CanDeactivateDialogComponent } from '../can-deactivate-dialog/can-deactivate-dialog.component';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

export function CanDeactivateGuard(component: any) {
  const dialog = inject(MatDialog);

  return component?.canDeactivate
    ? true
    : lastValueFrom(dialog.open(CanDeactivateDialogComponent)
      .afterClosed().pipe(
        map(result => result === true)
      ))
}
