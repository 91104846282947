export interface Address {
    uid: string;
    name: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    postalCode: number;
}

export class Address {
    constructor(data: Partial<Address> = {},) {
        this.uid = data.uid ? data.uid : null;
        this.name = data.name ? data.name : null;
        this.addressLine1 = data.addressLine1 ? data.addressLine1 : null;
        this.addressLine2 = data.addressLine2 ? data.addressLine2 : null;
        this.city = data.city ? data.city : null;
        this.state = data.state ? data.state : null;
        this.postalCode = data.postalCode ? data.postalCode : null;
    }
}

export interface ShipmentAddresses {
    pickUp: Address;
    deliverTo: Address;
}

