import { createSelector } from "@ngxs/store";

export class BaseCollectionsState {
  ///Selectors
  static loading() {
    return createSelector([this], (state: { loading: boolean }) => {
      return state.loading;
    });
  }

  /// ToDo create shared abstract state for all Jtms collections.......

  /// Helpers
  static sortByActive(items: any[], active: string, direction: 'asc' | 'desc') {
    if (active === 'dateOut') {
      return this.sortByDateField('dateOut', items, direction, 'end')
    } else {
      return [...items].sort((a, b) => {
        if (direction === 'asc') {
          return a[active]?.localeCompare(b[active]);
        } else if (direction === 'desc') {
          return b[active]?.localeCompare(a[active]);
        }
      })
    }
  }

  static sortByDateField(dateField: string, items: any[], direction: 'asc' | 'desc', empty: 'start' | 'end') {
    return [...items].sort((a, b) => {
      const dateA = Date.parse(a[dateField]);
      const dateB = Date.parse(b[dateField]);
      // console.log({dateA, dateB})
      if (Number.isNaN(dateA)) { return empty === 'start' ? -1 : 1 };
      if (Number.isNaN(dateB)) { return empty === 'start' ? 1 : -1 };
      if (dateA === dateB) { return 0 };
      return direction === 'asc' ? dateA > dateB ? 1 : -1 : dateA < dateB ? 1 : -1;
    })
  }

  static sortByDateObject(dateField: string, subField: string, items: any[], direction: 'asc' | 'desc', empty: 'start' | 'end') {
    return [...items].sort((a, b) => {

      const fieldA = a[dateField][subField];
      const fieldB = b[dateField][subField];


      const dateInputA = fieldA?.constructor === Array ? fieldA[fieldA.length - 1] : fieldA;
      const dateInputB = fieldB?.constructor === Array ? fieldB[fieldB.length - 1] : fieldB;

      const dateA = Date.parse(dateInputA);
      const dateB = Date.parse(dateInputB);

      // console.log({dateA, dateB})
      if (Number.isNaN(dateA)) { return empty === 'start' ? -1 : 1 };
      if (Number.isNaN(dateB)) { return empty === 'start' ? 1 : -1 };
      if (dateA === dateB) { return 0 };
      return direction === 'asc' ? dateA > dateB ? 1 : -1 : dateA < dateB ? 1 : -1;
    })
  }

  static sortByAlphabetical(field: string, items: any[]) {
    return [...items].sort((a, b) => {
      return a[field].toUpperCase() < b[field].toUpperCase() ? -1 : a[field].toUpperCase() > b[field].toUpperCase() ? 1 : 0;
    })
  }

  static sortByStatus(items: any[], statuses: string[]) {
    return [...items].sort((a, b) => {
      const aIndex = statuses.indexOf(a.status);
      const bIndex = statuses.indexOf(b.status);
      return aIndex - bIndex;
    })
  }

  /// Mat table filter functions ///
  static filterPredicate: (data: any, filter: string) => boolean = (data: any, filter: string): boolean => {
    // Transform the data into a lowercase string of all property values.
    const dataStr = Object.keys(data as unknown as Record<string, any>)
      .reduce((currentTerm: string, key: string) => {
        // Use an obscure Unicode character to delimit the words in the concatenated string.
        // This avoids matches where the values of two columns combined will match the user's query
        // (e.g. `Flute` and `Stop` will match `Test`). The character is intended to be something
        // that has a very low chance of being typed in by somebody in a text field. This one in
        // particular is "White up-pointing triangle with dot" from
        // https://en.wikipedia.org/wiki/List_of_Unicode_characters
        return currentTerm + (data as unknown as Record<string, any>)[key] + '◬';
      }, '')
      .toLowerCase();

    // Transform the filter by converting it to lowercase and removing whitespace.
    const transformedFilter = filter.trim().toLowerCase();

    return dataStr.indexOf(transformedFilter) != -1;
  };

}
