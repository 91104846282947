import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppScriptsApiService {

  /**
 * Load the API and make an API call.  Display the results on the screen.
 */
  async callScriptFunction<T>({ functionName, parameters }: { functionName: string; parameters?: any[]; }): Promise<T | T[] | any> {

    const scriptId = '1oT1bw1wlXcYWwC2JQjIFpRwVjSN7pNYEoZrp-0ZcrlUeXMQQfbEInPvU';

    // Call the Apps Script API run method
    //   'scriptId' is the URL parameter that states what script to run
    //   'resource' describes the run request body (with the function name
    //              to execute)
    console.log('callScriptFunction', {functionName, parameters});
    // @ts-ignore
    const resp = await gapi.client.script.scripts.run({
      'scriptId': scriptId,
      // @ts-ignore
      'resource': {
        'function': functionName,
        'parameters': parameters || null,
        'devMode': true
      }
    });
    const result = resp.result;
    if (result.error && result.error.code) {
      // The API encountered a problem before the script
      // started executing.
      console.error('Error calling API:', JSON.stringify(result, null, 2));
    } else if (result.error) {
      // The API executed, but the script returned an error.
      // Extract the first (and only) set of error details.
      // The values of this object are the script's 'errorMessage' and
      // 'errorType', and an array of stack trace elements.
      const error = result.error.details[0];
      console.error('Script error message: ' + error['errorMessage']);

      if (error['scriptStackTraceElements']) {
        // There may not be a stacktrace if the script didn't start
        // executing.
        console.error('Script error stacktrace:');
        for (let i = 0; i < error['scriptStackTraceElements'].length; i++) {
          const trace = error['scriptStackTraceElements'][i];
          console.error('\t' + trace.function + ':' + trace.lineNumber);
        }
      }
    } else {
      // The structure of the result will depend upon what the Apps
      // Script function returns. Here, the function returns an Apps
      // Script Object with String keys and values, and so the result
      // is treated as a JavaScript object.
      const script = result.response['result'];
      if (script && script.length === 0) {
        console.log('No script result returned!');
        return [];
      } else if (script) {
        // console.log('Script Result', script);
        return JSON.parse(script);
      }
    }
  }
}
